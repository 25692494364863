
import { useState, useEffect, useContext } from 'react'

import LoadingContext from "../../context/Loading/Loading";

import { getEvent } from '../../utilities/api';

import { PageLoadingContainer } from '../PageLoadingContainer';
import { SelectMap } from "./SelectMap";
import { ConfigureMap } from "./ConfigureMap";
import { InventoryProvider } from './InventoryProvider/InventoryProvider';

export default function SeatMapWrapper({ eventId, offerId }) {

    const { isLoading, showLoading, hideLoading } = useContext(LoadingContext)

    const [step, setStep] = useState(1)

    const [event, setEvent] = useState()

    const [data, setData] = useState({})

    const [background, setBackground] = useState(null)

    const [activeTab, setActiveTab] = useState('scaling');

    const [isTemplate, setIsTemplate] = useState(false);

    // selected map id
    const [selectedMapId, setSelectedMapId] = useState(null)

    // all event templates
    const [templates, setTemplates] = useState([])

    // all event map configurations
    const [configurations, setConfigurations] = useState([])

    // selected map configuration - not template  
    const [configuration, setConfiguration] = useState()

    // selected template
    const [template, setTemplate] = useState()

    const [isReloading, setIsReloading] = useState(false)

    useEffect(() => {
        showLoading()
        loadEvent(eventId).then((res) => {
            // set all seat map templates
            setTemplates(res?.data?.templates)
            // set all seat map configurations 
            setConfigurations(res?.data?.room?.seatmaps)
            // get if map is a template from sessions 
            setIsTemplate(JSON.parse(sessionStorage.getItem('isTemplate')))
            // set map data, id and configuration
            loadMapData(res?.data).then((res) => {
                // if there is map selected skip step 1 and go right to map configurations
                if (res?.mapId) setStep(2)
                hideLoading()
            })
        })
    }, [eventId])

    useEffect(() => {
        // if map is template, get template from selected map id to get price levels/holds/kills 
        if (isTemplate) {
            const template = templates?.find(template => template.id == selectedMapId)
            setTemplate(template)
        }
        else {
            setTemplate()
        }

    }, [templates, isTemplate, selectedMapId])

    // load/reload event -> reload event to set seatmap used when creating new offer and saving offer when leaving page 
    // reloads Standard Admission offer inventory because event has new seatmap or template 
    const loadEvent = (eventId) => {
        return new Promise((resolve, reject) => {
            getEvent(eventId)
                .then((res) => {
                    setEvent(res.data)
                    resolve(res)
                })
                .catch((err) => {
                    console.error(err)
                    reject()
                })
        })
    }

    // set map data, map id and configuration 
    const loadMapData = (event) => {
        return new Promise((resolve) => {
            setData(event?.seatmap?.mapping)
            setBackground(event?.seatmap?.background)
            console.log("event.seatmap", event)
            // get map id -> from event, meaning same event or changed to different event, or new event 
            const selectedId = event?.seatmap?.id || undefined
            setSelectedMapId(selectedId)
            if (selectedId) sessionStorage.setItem('mapId', JSON.stringify(selectedId))
            // set map configuration 
            setConfiguration(event?.seatmap)
            resolve({ mapId: selectedId, data: event?.seatmap?.mapping });
        })
    }

    // called every time map configuration changes
    const reloadData = () => {
        setIsReloading(true)
        loadEvent(eventId).then((res) => {
            // get event map data, id and configuration
            loadMapData(res.data).then(() => {
                setIsReloading(false)
                setActiveTab("scaling")
            })
        })
    }

    return (
        <>
            {isLoading ? (
                <PageLoadingContainer />
            ) : (
                <>
                    {step === 1 ? (
                        <SelectMap
                            eventId={eventId}
                            setStep={setStep}
                            setIsTemplate={setIsTemplate}
                            setSelectedMapId={setSelectedMapId}
                            templates={templates}
                            seatmaps={configurations}
                            reloadData={reloadData}
                        />
                    ) : (
                        <>
                            {data && Object.values(data).length > 0 ? (
                                <InventoryProvider>
                                    <ConfigureMap
                                        eventId={eventId}
                                        offerId={offerId}
                                        activeTab={activeTab}
                                        setActiveTab={setActiveTab}
                                        isTemplate={isTemplate}
                                        setIsTemplate={setIsTemplate}
                                        selectedMapId={selectedMapId}
                                        setSelectedMapId={setSelectedMapId}
                                        event={event}
                                        data={data}
                                        background={background}
                                        setData={setData}
                                        templates={templates}
                                        setTemplates={setTemplates}
                                        seatmaps={configurations}
                                        configuration={configuration}
                                        template={template}
                                        setTemplate={setTemplate}
                                        isReloading={isReloading}
                                        setIsReloading={setIsReloading}
                                        loadEvent={loadEvent}
                                        reloadData={reloadData}
                                    />
                                </InventoryProvider>
                            ) : (
                                <PageLoadingContainer />
                            )}
                        </>
                    )
                    }
                </>
            )}
        </>

    )
}