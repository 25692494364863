import React from 'react';

import { formatCurrency, formatNumber } from '../../../../../utilities/helpers';

import Form from 'react-bootstrap/Form'
import InputGroup from 'react-bootstrap/InputGroup'
import Button from 'react-bootstrap/Button'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Tooltip from 'react-bootstrap/Tooltip'

import { ItemName } from '../../../../ItemName';

export default function Level({ level, isDisabled, canEdit, isPriceEditable, hasDiscountError, handleChange, handleShow }) {
    return (
        <div className='flex-row' role="rowgroup">
            <div className='list-table-col text-truncate' role="cell">
                <ItemName el={level} size="lg" />
            </div>
            <div className='list-table-col' role="cell">
                <span>{formatCurrency(level?.basePrice)}</span>
            </div>
            <div className="list-table-col sm" role="cell">
                <span>{formatNumber(level?.seats?.length)}</span>
            </div>
            <div className="list-table-col lg" role="cell">
                {isPriceEditable ? (
                    <InputGroup className='input-group-sm m-auto'>
                        <InputGroup.Text id="offerPrice-val" className='input-group-text-sm'>$</InputGroup.Text>
                        <Form.Control
                            size="sm"
                            placeholder="Price"
                            id="price-val"
                            aria-describedby="price-val"
                            disabled={!canEdit}
                            name="offerPrice"
                            pattern="^[0-9.]*$"
                            value={level?.offerPrice}
                            onChange={(e) => handleChange(e.target.validity.valid || e.target.value === '' ? e : level?.offerPrice, level?.id)}
                        />
                    </InputGroup>
                ) : (
                    <span className={`${hasDiscountError && level?.offerPrice < 0 ? 'text-danger' : ''}`}>{formatCurrency(level?.offerPrice)}</span>
                )}
            </div>
            <div className="list-table-col lg" role="cell">
                <InputGroup className='input-group-sm m-auto'>
                    <InputGroup.Text id="fee-val" className='input-group-text-sm'>$</InputGroup.Text>
                    <Form.Control
                        size="sm"
                        placeholder="Fee"
                        id="fee-val"
                        name="fee"
                        pattern="^[0-9.]*$"
                        disabled={!canEdit}
                        value={level?.fee}
                        onChange={(e) => handleChange(e.target.validity.valid || e.target.value === '' ? e : level?.fee, level?.id)}
                        aria-describedby="fee-val"
                    />
                </InputGroup>
            </div>
            <div className="list-table-col" role="cell">
                <Button variant="link" onClick={() => handleShow(level)}>View</Button>
            </div>
            <div className="btn-more-col" role="cell">
                <OverlayTrigger
                    placement="bottom"
                    overlay={<Tooltip>Include in offer</Tooltip>}>
                    <Form.Check
                        type="switch"
                        id="toggle-level-switch"
                        name="isIncluded"
                        disabled={isDisabled || !canEdit}
                        checked={level?.isIncluded}
                        title="Included"
                        aria-label="Toggle Level"
                        onChange={(e) => handleChange(e, level?.id, e.target.checked)}
                    />
                </OverlayTrigger>
            </div>
        </div>
    );
}