import React, { useEffect, useState, useContext, useRef } from 'react';
import { useNavigate } from 'react-router-dom';

import LoadingContext from '../../context/Loading/Loading';
import AuthService from '../../utilities/services/auth.service';
import UserContext from '../../context/User/User';

import { checkPermission } from '../../utilities/helpers';
import { getPackage } from '../../utilities/api';

import Form from 'react-bootstrap/Form';
import Card from 'react-bootstrap/Card';
import Alert from 'react-bootstrap/Alert';

import { NoPermissionsContainer } from '../NoPermissionsContainer';
import { PageLoadingContainer } from '../PageLoadingContainer';

export default function PackageSeatmapWrapper({ uuid }) {
  const navigate = useNavigate();
  const { isLoading, showLoading, hideLoading } = useContext(LoadingContext)
  const { orgPermissions } = useContext(UserContext)
  const organization = AuthService.getOrg()[0];
  const { getPermissions } = AuthService;
  const [hasPermission, setHasPermission] = useState(true);
  const [isSaving, setSaving] = useState(false)
  const [showFooter, setShowFooter] = useState(false);
  const [currentPackage, setPackage] = useState()
  const [seatmap, setSeatmap] = useState()

  const loadPackage = () => {
    getPackage(uuid)
      .then((res) => {
        setPackage(res?.data.data[0]);
        setSeatmap(res?.data.data[0]?.attributes.seatmap.data.attributes)
      })
      .catch((err) => console.error(err))
    
  }

  const handleSave = () => {
    console.log('Saving')
  }

  useEffect(() => {
    loadPackage()
  }, [])

  useEffect(() => {
    if (seatmap) console.log(seatmap)
  },[seatmap])

  return (
    <>
            {isLoading ? (
                <PageLoadingContainer />
            ) : (
                <div className='position-relative'>
                    <section className={`wrapper package-form ${!hasPermission ? 'overlay' : ''}`}>
                        {alert.show &&
                            <>
                                <Alert variant={alert.variant} className="mb-5">
                                    <p>{alert.message}</p>
                                </Alert>
                            </>
                        }
                        <header className="section-header-sm section-heading section-heading--secondary">
                            <h1>Seatmap</h1>
                        </header>
                        <Form onSubmit={handleSave}>
                            <Card body className='card--md'>
                            </Card>
                        </Form>
                    </section>

                    {showFooter && (
                        // <CreatePackageButton
                        //   isEditing={true} 
                        //   isSaving={isSaving} 
                        //   showGoBack={false}
                        //   handleSave={handleSave}
                        //   handleGoBack={handleGoBack}
                        // />
                        <button>Button</button>
                    )}

                    {!hasPermission && (
                        <NoPermissionsContainer />
                    )}
                </div>
            )
            }
    </>
  )
}