import React, { useEffect, useState } from 'react';
import moment from 'moment'
import { isEqual } from 'lodash'

import { formatDateTime, getTimezoneDate, getFormattedTimezoneDate, getIsTimeAfterEventStartErrorMsg, getIsTimeBeforeEventVisibilityErrorMsg, isTimeAfterEventStart, isTimeBeforeEventVisibility } from "../../../../utilities/helpers";

import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';

import { Period } from './Period';
import { CreatePeriodModal } from './CreatePeriodModal';
import { GeneralOnsaleModal } from '../../../GeneralOnsaleModal';
import { DeleteModal } from '../../../DeleteModal';
import { WarningCard } from '../../../WarningCard';

export default function Availability({ openOffers, isStandard, event, offers, offer, setOffer, selected, setSelected, isEditing, canEdit }) {

    const [show, setShow] = useState(false)

    const [showGeneralOnsale, setShowGeneralOnsale] = useState(false)

    const [showDelete, setShowDelete] = useState(false)

    const [showPeriods, setShowPeriods] = useState(false)

    const [periods, setPeriods] = useState([])

    // period id used for editing and deleting 
    const [id, setId] = useState()

    // single period for viewing and editing 
    const [period, setPeriod] = useState()

    const [initialState, setInitialState] = useState()

    const [name, setName] = useState('');

    const [hasGeneralOnsalePeriod, setHasGeneralOnsalePeriod] = useState(false)

    const [generalOnsale, setGeneralOnsale] = useState(null);

    const [starts, setStarts] = useState(null)

    const [ends, setEnds] = useState(null)

    const [generalOnsaleError, setGeneralOnsaleError] = useState(false)

    const [startError, setStartError] = useState(false)

    const [endError, setEndError] = useState(false)

    const [errorMsg, setErrorMsg] = useState('')

    const [endErrorMsg, setEndErrorMsg] = useState('')

    const [isSaving, setIsSaving] = useState(false)

    useEffect(() => {
        setInitialState({ name: period?.name || "", starts: period?.starts || null, ends: period?.ends || null })
    }, [period])

    useEffect(() => {
        setPeriods(offer?.availabilityPeriods)
    }, [offer])

    useEffect(() => {
        if (isEditing) {
            // set only active period
            setPeriod(periods?.find(period => period.active))
        }
    }, [periods])

    // validation for general onsale date
    // - cannot be after event start time 
    // - cannot be before event visibility
    useEffect(() => {
        setGeneralOnsaleError(isTimeAfterEventStart(generalOnsale, event) || isTimeBeforeEventVisibility(generalOnsale, event))
        setErrorMsg(getErrorMsg())
    }, [generalOnsale, event])

    // validation for start date
    // - cannot be after event start time 
    // - cannot be before event visibility
    // validation for end date
    // - cannot be after event end time
    // - cannot be before/same as start time 
    useEffect(() => {
        if (starts, ends) {
            setStartError(isTimeAfterEventStart(starts, event) || isTimeBeforeEventVisibility(starts, event))
            setEndError(isEndAfterEventEnd(ends) || moment(ends).toDate().getTime() <= moment(starts).toDate().getTime())
            setErrorMsg(getErrorMsg())
            setEndErrorMsg(getIsEndBeforeOrSameAsStartErrorMsg() || getIsEndAfterEventEndErrorMsg(ends) || '')
        }
    }, [starts, ends, event])

    useEffect(() => {

    }, [offer])

    // validation for start date/general onsale 
    // - cannot be before event visibility
    // - cannot be after event start time 
    const getErrorMsg = () => {
        if (isTimeBeforeEventVisibility(starts, event) || isTimeBeforeEventVisibility(generalOnsale, event)) {
            return getIsTimeBeforeEventVisibilityErrorMsg(event)
        } else if (isTimeAfterEventStart(starts, event) || isTimeAfterEventStart(generalOnsale, event)) {
            return getIsTimeAfterEventStartErrorMsg(event)
        }
        else return ''
    }

    // validation for end date
    // - cannot be before/same as start time 
    const getIsEndBeforeOrSameAsStartErrorMsg = () => {
        if (moment(ends).toDate().getTime() <= moment(starts).toDate().getTime()) {
            return 'Time must be after start time'
        }
    }

    // end date cannot be after event end time (4 hours after event start time)
    const isEndAfterEventEnd = (date) => {
        return moment(date)?.toDate().getTime() > moment(getFormattedTimezoneDate(event?.end, event?.timezone))?.toDate().getTime()
    }

    // validation for end date
    // - cannot be before/same as start time 
    const getIsEndAfterEventEndErrorMsg = () => {
        if (isEndAfterEventEnd(ends)) {
            return `Time cannot be after event end time (${formatDateTime(getTimezoneDate(event?.end, event?.timezone), 'timeOnly')})`
        }
    }

    const handleShow = (_, period) => {
        if (period) {
            if (period?.name?.includes("General On-sale")) {
                setShowGeneralOnsale(true)
                setHasGeneralOnsalePeriod(true)
                setGeneralOnsale(new Date(getFormattedTimezoneDate(period?.starts, event?.timezone)))
            } else {
                setShow(true)
                setId(period?.id)
                setPeriod(period)
                setName(period?.name)
                setStarts(new Date(getFormattedTimezoneDate(period?.starts, event?.timezone)))
                setEnds(new Date(getFormattedTimezoneDate(period?.ends, event?.timezone)))
            }
        } else setShow(true)
    }

    // create new/edit period
    const handleSubmit = (e, isUpdate = false) => {
        console.log(isUpdate);
        e.preventDefault();
        e.stopPropagation();
        if (!name || !starts || !ends) return
        const periodData = {
            name,
            starts: getTimezoneDate(starts, event?.timezone, true).format(),
            ends: getTimezoneDate(ends, event?.timezone, true).format(),
        }
        // edit period
        if (isUpdate) {
            const updatedPeriod = { ...period, ...periodData }
            const periodIndex = periods?.findIndex(period => period.id === selected)
            console.log(periodIndex);
            setOffer({
                ...offer,
                availabilityPeriods: offer.availabilityPeriods.map((period, index) =>
                    index === periodIndex ? updatedPeriod : period
                )
            });
        } else {
            // create new period
            const newPeriod = {
                ...periodData,
                id: Math.max(...offer.availabilityPeriods.map(period => period.id)) + 1,
                active: true,
            }

            // add latest period created to offer's existing periods 
            setOffer({ ...offer, availabilityPeriods: [...offer.availabilityPeriods, newPeriod] })

            setSelected(newPeriod.id)
        }

        handleClose()
    }

    // close create new period
    const handleClose = () => {
        setName("")
        setStarts(null)
        setEnds(null)
        setShow(false)
        setId()
        if (!isEditing) {
            setPeriod()
        }
    }

    // TODO: edit general onsale period 
    const handleSubmitGeneralOnsale = () => {

    }

    // close general onsale modal 
    const handleCloseGeneralOnsale = () => {
        setGeneralOnsale(null)
        setHasGeneralOnsalePeriod(false)
        setShowGeneralOnsale(false)
    }

    const handleShowDelete = (id) => {
        setId(id)
        setShowDelete(true)
    }

    const handleCloseDelete = () => {
        setId()
        setShowDelete(false)
    }

    // remove availability period
    const handleDelete = () => {
        const updatedPeriods = periods?.filter(period => period.id !== id)

        // if period to delete is selected, change the default selected to the first period
        if (id === selected) {
            setSelected(periods[0]?.id)
        }

        setOffer({ ...offer, availabilityPeriods: updatedPeriods })

        handleCloseDelete()
    }

    const getText = () => {

        if (canEdit) {
            if (isEditing) return 'Edit availability period'
            else return 'Select or create an availability period'
        }
        else return 'Availability period'
    }


    const checkIsDisabled = () => {
        const formattedInitialStarts = new Date(getFormattedTimezoneDate(initialState?.starts, event?.timezone));
        console.log(formattedInitialStarts, new Date(starts));
        const formattedInitialEnds = new Date(getFormattedTimezoneDate(initialState?.ends, event?.timezone));

        const hasEmptyFields = !name || !starts || !ends;
        const hasError = startError || endError;
        const isDateEqual = (date1, date2) => isEqual(date1, new Date(date2));
        const isFormattedDateEqual = (date, formattedInitialDate) => isEqual(new Date(date), formattedInitialDate);

        // all fields have to be filled in 
        const newPeriodChanges = name !== initialState?.name && !isDateEqual(starts, initialState?.starts) &&
            !isDateEqual(ends, initialState?.ends)

        // any field can be changed
        const updatedPeriodChanges = name !== initialState?.name || !isFormattedDateEqual(starts, formattedInitialStarts) ||
            !isDateEqual(ends, formattedInitialEnds)

        const isDisabled = (hasChanges) => {

            // If changes exist but there are empty fields or errors, return true
            if (hasChanges) {
                return hasEmptyFields || hasError;
            }

            // If no changes, disable the update (return true)
            return true;
        }

        return isDisabled(period ? updatedPeriodChanges : newPeriodChanges)
    }

    const isSameAsStandardPeriods = (selectedPeriodId, period) => {
        console.log(selectedPeriodId, period);
        if (offers) {
            const standardAdmissionOffer = offers[0]

            if (standardAdmissionOffer) {

                if (selectedPeriodId) {
                    // get all availability period ids
                    const periodIds = standardAdmissionOffer.availability.map(period => period.id)

                    return periodIds?.some(id => id === selected)
                } else if (period) {
                    // get all dates 
                    const periodDates = standardAdmissionOffer.availability.map(period => {
                        return (
                            {
                                starts: period.starts,
                                ends: period.ends
                            }
                        )
                    })

                    console.log(period, periodDates);

                    const isExactMatch = periodDate => {
                        console.log('exact match', (isEqual(new Date(period.starts), new Date(getFormattedTimezoneDate(periodDate.starts, event?.timezone))) && isEqual(new Date(period.ends), new Date(getFormattedTimezoneDate(periodDate.ends, event?.timezone)))));
                        return (isEqual(new Date(period.starts), new Date(getFormattedTimezoneDate(periodDate.starts, event?.timezone))) && isEqual(new Date(period.ends), new Date(getFormattedTimezoneDate(periodDate.ends, event?.timezone))))
                    }

                    const isInBetweenMatch = periodDate => {
                        console.log('in between match', (moment(period?.starts).toDate().getTime() >= moment(getFormattedTimezoneDate(periodDate?.starts, event?.timezone)).toDate().getTime() && moment(period?.ends).toDate().getTime() >= moment(getFormattedTimezoneDate(periodDate?.ends, event?.timezone)).toDate().getTime()));
                        return (moment(period?.starts).toDate().getTime() >= moment(getFormattedTimezoneDate(periodDate?.starts, event?.timezone)).toDate().getTime() && moment(period?.ends).toDate().getTime() >= moment(getFormattedTimezoneDate(periodDate?.ends, event?.timezone)).toDate().getTime())
                    }

                    return periodDates?.some(periodDate => isExactMatch(periodDate) || isInBetweenMatch(periodDate))
                }
            }
        }
    }

    return (
        <>
            <div className="card-body-heading--sm card-body-heading--flex card-body-heading--flex-space-between">
                <div className='d-flex-column'>
                    <Card.Title as="h5" className='card-title-sm'>Availability</Card.Title>
                    <Card.Subtitle as="h6" className="subtitle--dark">{getText()}</Card.Subtitle>
                </div>
                {!isEditing && (
                    <Button
                        variant="outline-light"
                        className="btn-plus"
                        onClick={handleShow}
                    >
                        Create period
                    </Button>
                )}
            </div>
            {!isStandard && isSameAsStandardPeriods(selected) && (<WarningCard text={`This offer will override the Standard Admission offer within the selected timeframe`} variant="primary" />)}
            {(isEditing && period) ? (
                <div className="offset-container-sm">
                    <Period period={period} timezone={event?.timezone} selected={period?.id} isEditing={isEditing} isDisabled={!canEdit} canEdit={canEdit && !period.name.includes('General On-sale')} status={offer?.status} handleShow={handleShow} />
                </div>
            ) : (
                <ul className='offset-container-sm'>
                    {periods?.map((period, index) => (
                        <Period key={index} period={period} timezone={event?.timezone} selected={selected} setSelected={setSelected} isEditing={isEditing} canEdit={index >= 2} canDelete={index >= 2} handleShow={handleShow} handleShowDelete={handleShowDelete} />
                    ))}
                </ul>
            )}
            {hasGeneralOnsalePeriod ? (
                <GeneralOnsaleModal show={showGeneralOnsale} event={event} eventStart={new Date(getFormattedTimezoneDate(event?.start, event?.timezone))} eventVisibility={event?.eventVisibility ? new Date(getFormattedTimezoneDate(event?.eventVisibility, event?.timezone)) : undefined} generalOnsale={generalOnsale} setGeneralOnsale={setGeneralOnsale} generalOnsaleEnd={new Date(getFormattedTimezoneDate(event?.end, event?.timezone))} error={generalOnsaleError} errorMsg={errorMsg} isDisabled={isEqual(generalOnsale, new Date(getFormattedTimezoneDate(event?.generalOnsale, event?.timezone)))} isSaving={isSaving} handleSubmit={handleSubmit} handleClose={handleCloseGeneralOnsale} />
            ) : (
                <CreatePeriodModal show={show} id={id} eventStart={new Date(getFormattedTimezoneDate(event?.start, event?.timezone))} eventEnd={new Date(getFormattedTimezoneDate(event?.end, event?.timezone))} eventVisibility={event?.eventVisibility ? new Date(getFormattedTimezoneDate(event?.eventVisibility, event?.timezone)) : undefined} showPeriods={showPeriods} setShowPeriods={setShowPeriods} periods={periods} name={name} setName={setName} starts={starts} setStarts={setStarts} ends={ends} setEnds={setEnds} startError={startError} endError={endError} errorMsg={errorMsg} endErrorMsg={endErrorMsg} timezone={event?.timezone} isDisabled={checkIsDisabled()} isSaving={isSaving} handleSubmit={handleSubmit} handleClose={handleClose} isSameAsStandardPeriods={isSameAsStandardPeriods} />
            )}

            <DeleteModal show={showDelete} entity="availability period" handleDelete={handleDelete} handleClose={handleCloseDelete} />
        </>
    );
}