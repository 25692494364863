import React, { useState, useEffect, useLayoutEffect } from 'react';
import { Outlet, useParams } from 'react-router-dom';
import moment from 'moment'

import EventDetailsContext from '../../context/EventDetails/EventDetails'

import { getEvent } from '../../utilities/api';

import { PageLoadingContainer, Sidenav, EventBanner } from '../../components';
import { getTimezoneDate } from '../../utilities/helpers';

export default function MyEventPage() {

    const { uuid } = useParams()

    const [windowSize, setWindowSize] = useState(undefined);

    const [isLoading, setIsLoading] = useState(false);

    const [event, setEvent] = useState()

    const [eventContext, setEventContext] = useState()

    const [eventStart, setEventStart] = useState(null);

    const [eventVisibility, setEventVisibility] = useState(null);

    const [initialEventVisibility, setInitialEventVisibility] = useState(null);

    const [generalOnsale, setGeneralOnsale] = useState(null);

    const [standardAdmissionOfferHasInventory, setStandardAdmissionOfferHasInventory] = useState(false)

    const [canPublish, setCanPublish] = useState(false)

    const [isEventPublished, setIsEventPublished] = useState(false)

    const [isEventOnsale, setIsEventOnsale] = useState(false)

    useEffect(() => {
        // Handler to call on window resize
        function handleResize() {
            const el = document.querySelector("html")
            // Set window width/height to state
            setWindowSize(el.clientWidth);
        }
        // Add event listener
        window.addEventListener('resize', handleResize);
        // Call handler right away so state gets updated with initial window size
        handleResize();
        // Remove event listener on cleanup
        return () => window.removeEventListener('resize', handleResize);
    })

    useLayoutEffect(() => {
        const el = document.querySelector("#banner")
        if (el) el.style.width = `${windowSize}px`
    }, [windowSize])

    useEffect(() => {
        setIsLoading(true)
        getEvent(uuid)
            .then((res) => {
                setEvent(res?.data)
                setIsLoading(false);
            }).catch((err) => {
                console.error(err)
                setIsLoading(false)
            })
    }, [uuid])

    useEffect(() => {
        // set state for event details context
        updateEvent(event)
        setEventStart(event?.start)
        const eventVisibility = event?.eventVisibility;
        setEventVisibility(eventVisibility ? eventVisibility : null)
        setInitialEventVisibility(eventVisibility ? eventVisibility : null)
        setIsEventPublished(event?.status === "on_sale")
        setGeneralOnsale(event?.generalOnsale)
        // check if all "seats" are assigned to price levels 
        // compare the seats in pricing levels to the "seats" in seatmap 
        if (event?.offers && event?.offers.length > 0 && (event?.offers[0]?.pricing_levels && event?.seatmap)) {
            let arr = []
            let pricingLevels = event?.offers[0]?.pricing_levels
            let keys = Object.keys(pricingLevels)

            for (let key of keys) {
                let pricing = pricingLevels[key]
                arr.push({ seats: pricing.seats })
            }

            let seats = []

            seats.push({ seats: Object.keys(event?.seatmap?.mapping?.seats) })

            // get GA "seats"
            Object.values(event?.seatmap?.mapping?.sections).forEach((section) => {
                if (section?.spots) {
                    seats.push({ seats: section?.spots })
                }
            })

            const totalSeats = seats?.flatMap(arr => arr.seats)

            const allPriceLevelSeats = arr?.flatMap(level => level.seats)
            const standardAdmissionOfferHasInventory = allPriceLevelSeats?.length == totalSeats?.length
            setCanPublish(event?.image?.id && standardAdmissionOfferHasInventory)
            setStandardAdmissionOfferHasInventory(standardAdmissionOfferHasInventory)
        }
        updateIsEventOnsale(event)
    }, [event])

    const updateEvent = (event) => {
        setEventContext(event)
    }

    // can publish when event is published or when event settings are filled out and standard admission has completed inventory 
    const updateCanPublish = (image = eventContext?.image?.id, hasInventory = standardAdmissionOfferHasInventory) => {
        console.log(image, hasInventory);
        setCanPublish(image && hasInventory)
    }

    const getActiveAvailabilityPeriod = (offers) => {
        let activePeriods = [];
        offers?.map(offer => {
            activePeriods = [...activePeriods, ...offer?.availability?.filter(period => period.active)]
        })
        return activePeriods;
    }
    // event is published and has at least one offer onsale 
    // flag will be false if all offers are sold out - need to check soldOut flag on offer ?
    const updateIsEventOnsale = (event) => {
        const activePeriods = getActiveAvailabilityPeriod(event?.offers);

        setIsEventOnsale(event?.status === 'on_sale' && activePeriods.some(period => getTimezoneDate(period.starts, event.timezone).isSameOrBefore(getTimezoneDate(moment(), event.timezone))));
    }

    return (
        <>
            {isLoading ? (
                <PageLoadingContainer style="without-sidebar" />
            ) : (
                <>
                    <EventDetailsContext.Provider value={{
                        event: eventContext,
                        updateEvent,
                        eventStart,
                        storeEventStart: setEventStart,
                        eventVisibility,
                        storeEventVisibility: setEventVisibility,
                        initialEventVisibility,
                        updateInitialEventVisibility: setInitialEventVisibility,
                        generalOnsale,
                        storeGeneralOnsale: setGeneralOnsale,
                        standardAdmissionOfferHasInventory,
                        setStandardAdmissionOfferHasInventory,
                        canPublish,
                        updateCanPublish,
                        isEventPublished,
                        setIsEventPublished,
                        isEventOnsale,
                        setIsEventOnsale,
                        updateIsEventOnsale
                    }}>
                        <EventBanner eventId={uuid} event={event} />
                        <Sidenav />
                        <div className='spacer-md spacer-md--with-banner' id="main-content">
                            <Outlet />
                        </div>
                    </EventDetailsContext.Provider>
                </>
            )}
        </>
    )
}