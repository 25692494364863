import React from 'react';

import Button from 'react-bootstrap/Button';
import Stack from 'react-bootstrap/Stack';

import { EditButton } from '../../../../EditButton';
import { TrashButton } from '../../../../TrashButton';
import { ItemName } from '../../../../ItemName';

export default function Level({ level, isDisabled, handleShowAdd, handleShowDelete }) {

    const hasDescription = level?.description;

    return (
        <div className='flex-row' role="rowgroup">
            <div className='list-table-col text-truncate' role="cell">
                <ItemName el={level} size="lg" />
            </div>
            <div className='list-table-col text-left xxl' role="cell">
                {hasDescription ? (
                    <span className='d-block text-truncate' style={{ width: '46ch' }}>{level?.description}</span>
                ) : (
                    <Button
                        variant='link'
                        disabled={isDisabled}
                        onClick={(e) => handleShowAdd(e, level)}
                    >
                        Add
                    </Button>
                )}
            </div>
            <div className="list-table-col" role="cell">
                <Stack direction="horizontal" gap={3} className="justify-content-end">
                    <EditButton
                        variant='primary'
                        isDisabled={!hasDescription || isDisabled}
                        obj={level}
                        onClick={handleShowAdd}
                    />
                    <TrashButton
                        variant="danger"
                        isDisabled={!hasDescription || isDisabled}
                        obj={level}
                        onClick={handleShowDelete}
                    />
                </Stack>
            </div>
        </div>
    );
}
