import React, { useEffect, useState } from 'react';

import { formatCurrency, calculateFees, formatNumber } from "../../../../../utilities/helpers";

import Modal from 'react-bootstrap/Modal';
import Stack from 'react-bootstrap/Stack';
import CloseButton from 'react-bootstrap/CloseButton';

import { ItemName } from '../../../../ItemName';

export default function BreakdownModal({ show, level, pricingOption, discount, fees, taxRates, handleClose }) {

    const [breakdown, setBreakdown] = useState()

    useEffect(() => {
        setBreakdown(calculateFees(level, fees, taxRates));
    }, [level, fees, taxRates])

    const getOffset = (discount, price) => {
        const num = pricingOption?.value?.includes('decrease_by') ? discount : price;
        if (pricingOption?.value?.includes('percent')) {
            return `${num}%`
        } else {
            return `${formatCurrency(num)}`
        }
    }
    return (
        <Modal centered animation={false} fullscreen="md-down" show={show} onHide={handleClose} className='modal-sm modal--with-margin'>
            <Modal.Body>
                <div className="modal-body-heading modal-body-heading--flex">
                    <h1 className='modal-body-heading-title'>Ticket breakdown</h1>
                    <Stack direction='horizontal' className="justify-content-end">
                        <CloseButton onClick={handleClose} />
                    </Stack>
                </div>
                <ul>
                    <li>
                        <Stack direction="horizontal" gap={2} className='mb-2'>
                            <ItemName el={level} size="lg" />
                            <small className='small small-bold'>({formatNumber(level?.seats?.length)} {level?.seats?.length > 1 ? 'seats' : 'seat'})</small>
                        </Stack>
                        <ul>
                            <li className='list list-sm list-item list-item--vertical list-item-lg'>
                                <Stack as="ul" gap={2}>
                                    <Stack as="li" direction="horizontal" className="split-row">
                                        <span>Base price</span>
                                        <span>{formatCurrency(level?.basePrice)}</span>
                                    </Stack>
                                    <Stack as="li" direction="horizontal" className="split-row">
                                        <span>Offer price ({pricingOption?.label}{!pricingOption?.value?.includes('match') ? ` - ${getOffset(discount, level?.offerPrice)})` : ')'}</span>
                                        <span>{formatCurrency(level?.offerPrice)}</span>
                                    </Stack>
                                </Stack>
                            </li>
                            <li className='list list-sm list-item list-item--vertical list-item-lg'>
                                <p className='heading'>Cost to buyer</p>
                                <ul>
                                    <Stack as="li" direction="horizontal" className="split-row">
                                        <span>Ticket price</span>
                                        <span>{formatCurrency(level?.offerPrice)}</span>
                                    </Stack>
                                    <Stack as="li" direction="horizontal" className="split-row">
                                        <span>Service Fee</span>
                                        <span>{formatCurrency(breakdown?.serviceFees)}</span>
                                    </Stack>
                                    <Stack as="li" direction="horizontal" className="split-row">
                                        <span>Facility Fee</span>
                                        <span>{formatCurrency(level?.fee)}</span>
                                    </Stack>
                                    <Stack as="li" direction="horizontal" className="split-row">
                                        <span>Processing Fee</span>
                                        <span>{formatCurrency(breakdown?.processingFee + breakdown?.chargeBackProtection + breakdown?.serviceFees)}</span>
                                    </Stack>
                                    <Stack as="li" direction="horizontal" className="split-row">
                                        <span>Sales Tax</span>
                                        <span>{formatCurrency(breakdown?.salesTax)}</span>
                                    </Stack>
                                    <Stack as="li" direction="horizontal" className="mt-2 split-row seperator seperator-xs">
                                        <span className='small small-bold '>Total</span>
                                        <span className='small small-bold '>{formatCurrency(breakdown?.totalDue)}</span>
                                    </Stack>
                                </ul>
                            </li>
                            <li className='list list-sm list-item list-item--vertical list-item-lg'>
                                <p className='heading'>Your payout</p>
                                <ul>
                                    <Stack as="li" direction="horizontal" className="split-row">
                                        <span>Ticket revenue</span>
                                        <span>{formatCurrency(level?.offerPrice)}</span>
                                    </Stack>
                                    <Stack as="li" direction="horizontal" className="split-row">
                                        <span>Facility Fee</span>
                                        <span>{formatCurrency(level?.fee)}</span>
                                    </Stack>
                                    <Stack as="li" direction="horizontal" className="split-row seperator seperator-xs">
                                        <span className='small small-bold '>Total</span>
                                        <span className='small small-bold '>{formatCurrency(breakdown?.payout)}</span>
                                    </Stack>
                                </ul>
                            </li>
                        </ul>
                    </li>
                </ul>
            </Modal.Body>
        </Modal>

    );
}
