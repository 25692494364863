import React, { useContext, useEffect, useState } from 'react';
import moment from 'moment';

import EventDetailsContext from '../../../../context/EventDetails/EventDetails';

import { formatCurrency, formatDateTime, formatNumber, getTimezoneDate } from '../../../../utilities/helpers';

import Badge from 'react-bootstrap/Badge';
import Stack from 'react-bootstrap/Stack';
import Button from 'react-bootstrap/Button';

export default function Offer({ offer, offers, timezone, isStandard, seats, openInventory, handleClick, getPrice, getStatus }) {

    const { standardAdmissionOfferHasInventory } = useContext(EventDetailsContext)

    const [status, setStatus] = useState()

    const [saleStarts, setSaleStarts] = useState()

    const [saleEnds, setSaleEnds] = useState()

    const [minPrice, setMinPrice] = useState(0)

    const [maxPrice, setMaxPrice] = useState(0)

    useEffect(() => {
        const saleStarts = getSaleDate(offers, offer, 'starts')
        setSaleStarts(saleStarts)
        const saleEnds = getSaleDate(offers, offer, 'ends')
        setSaleEnds(saleEnds)
        const activePeriod = {
            starts: saleStarts,
            ends: saleEnds
        }
        setStatus(getStatus(activePeriod))
    }, [offers, offer])

    useEffect(() => {
        console.log()
        setMinPrice(Math.min(...Object.values(offers[0]?.pricing_levels)?.map((level, idx) => getPrice(level, Object.values(offer?.pricing_levels), idx, offer?.pricing_option, offer?.discount))))
        setMaxPrice(Math.max(...Object.values(offers[0]?.pricing_levels)?.map((level, idx) => getPrice(level, Object.values(offer?.pricing_levels), idx, offer?.pricing_option, offer?.discount))))
    }, [offers, offer])

    // if offer pulls from open inventory
    const hasOpenInventory = offer => {
        return offer?.inventoryType.toLowerCase() === 'open'
    }

    // if offers pulled from open inventory are complete - check price level seats 
    // if custom offers have inventory 
    const hasInventory = offer => {
        if (hasOpenInventory(offer)) {
            return standardAdmissionOfferHasInventory
        }
        else return seats?.length > 0
    }

    const getSaleDate = (offers, offer, property) => {
        if (offer?.availability?.length > 0) {
            const selectedPeriod = offer?.availability.find(availability => availability.active)
            // if selected period is one of the default general admission periods, get from Standard Admission offer
            if (selectedPeriod) {
                if (selectedPeriod?.name?.includes('General On-sale')) {
                    let standardAdmissionPeriod;
                    if (offers) standardAdmissionPeriod = offers[0]?.availability.find(availability => availability.name === selectedPeriod?.name)
                    return standardAdmissionPeriod[property]
                } else {
                    return selectedPeriod[property]
                }
            }
        }
    }

    return (
        <Button
            variant='default'
            as="li"
            className='list-item list-item-lg list-item--light btn-list-view btn-list-view-with-border btn-list-view-with-bg offer-row'
            onClick={(e) => handleClick(e, offer?.id, { ...offer, status: status?.type })}
        >
            <Stack direction='horizontal' gap={2} className='col-7'>
                <Stack gap={1}>
                    <h2 className='heading mb-2'>{offer?.name}</h2>
                    <Stack direction="horizontal" gap={2}>
                        <Badge bg="none" className={`badge--${status?.type} `}>{status?.label}</Badge>
                        <span className="subtitle m-0">
                            <span>{formatDateTime(getTimezoneDate(saleStarts, timezone), 'dateOnly')}<span className='time'>{formatDateTime(getTimezoneDate(saleStarts, timezone), 'timeOnly')}</span></span><span className='to'>{formatDateTime(getTimezoneDate(saleEnds, timezone), 'dateOnly')}<span className='time'>{formatDateTime(getTimezoneDate(saleEnds, timezone), 'timeOnly')}</span></span>
                        </span>
                    </Stack>
                    {!hasInventory(offer) && (
                        <div className="info-text info-text-md info-text-danger fw-normal"><span className="small">{(hasOpenInventory(offer) && openInventory?.seats?.length > 0 && !standardAdmissionOfferHasInventory) ? 'Inventory not complete' : 'No inventory created'}</span></div>
                    )}
                </Stack>
            </Stack>
            <Stack gap={2} className='col justify-content-center'>
                <span className='normal normal-bold'>{isStandard ? 'Base' : 'Offset'} Price: {formatCurrency(minPrice)}<span className="to">{formatCurrency(maxPrice)}</span></span>
                <span className='fw-normal caption'>{hasOpenInventory(offer) ? 'Open' : 'Exclusive'} ({formatNumber(hasOpenInventory(offer) ? openInventory?.seats?.length : seats?.length)})</span>
            </Stack>
        </Button>
    );
}