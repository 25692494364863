import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import isEqual from 'lodash/isEqual'

import { getFormattedTimezoneDate, getTimezoneDate } from '../../../utilities/helpers';
import { createDiscount, createOrEditOffers } from '../../../utilities/api';

import Alert from 'react-bootstrap/Alert';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';

import { BackButton } from "../../BackButton";
import { WarningCard } from "../../WarningCard";
import { Details } from './Details';
import { Inventory } from './Inventory';
import { PricingFees } from './PricingFees';
import { PricingLevelDescription } from './PricingLevelDescription';
import { Availability } from './Availability';
import { AccessCode } from './AccessCode';
import { TicketLimits } from './TicketLimits';
import { CreateEventButtons } from "../../CreateEventButtons";
import { DeleteModal } from '../../DeleteModal';
import { GoToSeatMapModal } from './GoToSeatMapModal';
import { OpenOffers } from './OpenOffers';

export default function CreateOfferWrapper({ eventId, event, eventInventory, openInventory, isDiscountedOffer, id, offers, currentOffer, standardAdmissionOfferHasInventory, setIsChangesMade, getDiscountedPrice, getAvailabilityPeriods, getOfferPrice, getOfferStatus, removeOffers, isRemoving }) {

    const navigate = useNavigate()

    // go to seat map popup
    const [show, setShow] = useState(false)

    // delete popup
    const [showDelete, setShowDelete] = useState(false)

    const [isStandard, setIsStandard] = useState(false)

    // editing existing offer 
    const [isEditing, setIsEditing] = useState(false)

    // can edit fields - based on status
    const [canEdit, setCanEdit] = useState(true)

    const [initialState, setInitialState] = useState()

    const [offer, setOffer] = useState({})

    const [inventory, setInventory] = useState({})

    const [priceLevels, setPriceLevels] = useState({})

    const [pricingOpt, setPricingOpt] = useState(null)

    const [discount, setDiscount] = useState('')

    const [availabilityPeriodId, setAvailabilityPeriodId] = useState(null)

    // flag to check if single access code tab is selected for validations 
    const [hasAccessCode, setHasAccessCode] = useState(false)

    // flag to check if exact ticket limit tab is selected for validations 
    const [hasExactLimit, setHasExactLimit] = useState(false)

    const [ticketLimits, setTicketLimits] = useState({
        hasMinQuantity: false,
        minQuantity: '',
        hasMaxQuantity: false,
        maxQuantity: '',
        hasMultipleOfQuantity: false,
        multipleOfQuantity: '',
        limit: ''
    })

    const [errors, setErrors] = useState([])

    const [originalQuantity, setOriginalQuantity] = useState();

    const [fees, setFees] = useState({
        primaryOver20: 0,
        primaryUnder20: 0,
        secondaryServiceFeeBuyer: 0,
        secondaryServiceFeeSeller: 0,
        stripeCharge: 0,
        stripeServicePecentage: 0
    })

    const [taxRates, setTaxRates] = useState({
        stateTaxRate: 0,
        localTaxRate: 0,
        combinedTaxRate: 0
    })

    // offer that discounted offer is based on
    const [baseOffer, setBaseOffer] = useState();

    const [address, setAddress] = useState()

    const [showFooter, setShowFooter] = useState(false)

    const [isSaving, setIsSaving] = useState(false);

    // newly created offer id - to pass to go to seatmap popup 
    const [newOfferId, setNewOfferId] = useState()

    const [alert, setAlert] = useState({
        show: false,
        variant: '',
        message: ''
    })

    const [hasRequiredFieldError, setHasRequiredFieldError] = useState(false)

    const [requiredFieldErrorStatus, setRequiredFieldErrorStatus] = useState({})

    useEffect(() => {
    }, [eventId])

    useEffect(() => {
        //     showLoading()
        //     if (!event?.venue.address) {
        //         hideLoading()
        //         return;
        //     }

        //     getTaxRates(event.venue.address[0].city, event.venue.address[0].state)
        //         .then((res) => {
        //             setTaxRates(res?.data?.sales_tax_rates[0])
        //         })
        //         .catch((err) => {
        //             console.error(err)
        //             hideLoading()
        //         })
    }, [event])

    useEffect(() => {
        setIsEditing(Boolean(id))
    }, [id])

    useEffect(() => {
        // can edit fields when editing/viewing offer

        // true - create offer 
        // true - edit offer 
        // false - view offer
        console.log(offer);
        if (offer?.availabilityPeriods) {
            const activePeriod = offer?.availabilityPeriods?.find(period => period.active)

            if (isEditing) setCanEdit((offer?.status && offer?.status === 'scheduled') || getTimezoneDate(activePeriod.starts, event?.timezone).isAfter(getTimezoneDate(moment(), event?.timezone, true)))
        }
    }, [isEditing, offer, event])

    useEffect(() => {

        // add price levels to offers
        // all offers will have the pricing levels of Standard Admission offer by default 
        const getPriceLevels = () => {
            let priceLevels = {};
            let standardAdmissionOfferPriceLevels = {};
            let existingOfferFields = {};

            if (offers) {
                standardAdmissionOfferPriceLevels = offers[0]?.pricing_levels

                // existing offer
                // get fields that are specific to every offer
                if (currentOffer && Object.keys(standardAdmissionOfferPriceLevels).length > 0) {
                    const currentOfferPriceLevels = Object.values(currentOffer?.pricing_levels)

                    existingOfferFields = Object.values(standardAdmissionOfferPriceLevels)?.map((level, idx) => {
                        // if offer pulls from custom inventory, get seats assigned to offer 
                        // otherwise get seats from each level 
                        const getSeats = (levelSeats, offerId) => {
                            return currentOffer?.inventoryType.toLowerCase() === 'exclusive' ? eventInventory?.offers[offerId]?.seats : levelSeats
                        }

                        return {
                            seats: getSeats(level?.seats, id),
                            isIncluded: currentOfferPriceLevels[idx]?.isIncluded,
                            fee: currentOfferPriceLevels[idx]?.fee?.toString(),
                            offerPrice: getOfferPrice(level, currentOfferPriceLevels, idx, currentOffer?.pricing_option, currentOffer?.discount)
                        }
                    })
                }

                // create price levels
                // all offers will have same level name, color, base price, description as Standard Admission offer
                Object.values(standardAdmissionOfferPriceLevels)?.map((level, idx) => {
                    priceLevels = {
                        ...priceLevels,
                        [level.id]: {
                            id: level?.id,
                            name: level?.name,
                            color: level?.color,
                            seats: existingOfferFields[idx]?.seats || level?.seats,
                            isIncluded: existingOfferFields[idx]?.isIncluded ?? true,
                            description: level?.description || '',
                            basePrice: level?.basePrice?.toString() || level?.price?.toString(),
                            offerPrice: existingOfferFields[idx]?.offerPrice || level?.price?.toString(),
                            fee: existingOfferFields[idx]?.fee || event?.facilityFees?.toString()
                        }
                    }
                })
            }

            return priceLevels
        }

        const getOffer = (offer) => {
            return {
                id: offer?.id,
                uuid: offer?.uuid,
                eventUUID: eventId,
                status: offer?.status || (currentOffer && getOfferStatus(getAvailabilityPeriods(currentOffer)?.find(period => period.active))?.type),
                name: offer?.name || '',
                description: offer?.description || '',
                availabilityPeriods: getAvailabilityPeriods(currentOffer),
                accessCode: offer?.accessCode || '',
                soldOut: offer?.soldOut || false
            }
        }

        const getSelected = (periods) => {
            return periods?.find(period => period.active)?.id
        }

        // save initial state to check whether to show footer 
        const offer = getOffer(currentOffer)
        setOffer(offer)
        setIsStandard(offer?.name === 'Standard Admission')
        const defaultPriceLevels = offers ? offers[0]?.pricing_levels : {};
        const priceLevels = getPriceLevels();
        setPriceLevels(priceLevels)
        const discount = currentOffer?.discount || '';
        let formattedDiscount = discount;
        if (discount) {
            if (currentOffer?.pricing_option === 'decrease_by_price') {
                formattedDiscount = currentOffer?.discount.toFixed(2)
            }
        }
        setDiscount(formattedDiscount)
        const pricingOption = currentOffer?.pricing_option || 'match_price';
        setPricingOpt(pricingOption)
        setAvailabilityPeriodId(getSelected(offer?.availabilityPeriods))
        const hasAccessCode = Boolean(offer?.accessCode);
        setHasAccessCode(hasAccessCode)
        const hasMinQuantity = currentOffer?.hasMinQuantity ?? false;
        const minQuantity = currentOffer?.minQuantity ?? '';
        const hasMaxQuantity = currentOffer?.hasMaxQuantity ?? false;
        const maxQuantity = currentOffer?.maxQuantity ?? '';
        const hasMultipleOfQuantity = currentOffer?.hasMultipleOfQuantity ?? false;
        const multipleOfQuantity = currentOffer?.multipleOf ?? '';
        const limit = currentOffer?.limit ?? '';
        const hasExactLimit = Boolean(limit)
        setHasExactLimit(hasExactLimit)
        setTicketLimits({
            hasMinQuantity,
            minQuantity,
            hasMaxQuantity,
            maxQuantity,
            hasMultipleOfQuantity,
            multipleOfQuantity,
            limit
        })

        // default baseOffer is Standard Admission 
        if (isDiscountedOffer) setBaseOffer(event?.offers[0])

        // set state
        saveInitialState(
            offer,
            inventory,
            defaultPriceLevels,
            priceLevels,
            pricingOption,
            formattedDiscount,
            getSelected(offer?.availabilityPeriods),
            hasAccessCode,
            hasMinQuantity,
            minQuantity,
            hasMaxQuantity,
            maxQuantity,
            hasMultipleOfQuantity,
            multipleOfQuantity,
            hasExactLimit,
            limit,
            event?.offers[0]
        )
    }, [eventId, event, currentOffer, isDiscountedOffer, offers, openInventory, eventInventory])

    // set show footer 
    useEffect(() => {

        // check if availability periods have changed from initial state
        const hasPeriodsChanged = (periods, initialPeriods) => {
            // Check if lengths are different
            if (periods.length !== initialPeriods.length) {
                return true; // Arrays are different
            }

            // Compare each period using `some` to find any difference
            return periods.some((period, index) => {
                const initialPeriod = initialPeriods[index];

                // Assuming periods have `starts` and `ends` properties that need comparison
                return !isEqual(period.name, initialPeriod.name) || !isEqual(new Date(getFormattedTimezoneDate(period.starts, event?.timezone)), new Date(getFormattedTimezoneDate(initialPeriod.starts, event?.timezone))
                ) || !isEqual(new Date(getFormattedTimezoneDate(period.ends, event?.timezone)), new Date(getFormattedTimezoneDate(initialPeriod.ends, event?.timezone))
                );
            });
        }
        setShowFooter(!isEqual(offer?.name, initialState?.offer?.name) || !isEqual(offer?.description, initialState?.offer?.description) || !isEqual(pricingOpt, initialState?.pricingOpt) || !isEqual(priceLevels, initialState?.priceLevels) || !isEqual(availabilityPeriodId, initialState?.availabilityPeriodId) || hasPeriodsChanged(offer?.availabilityPeriods, initialState?.offer?.availabilityPeriods) || !isEqual(hasAccessCode, initialState?.hasAccessCode) || !isEqual(offer?.accessCode, initialState?.offer?.accessCode) || !isEqual(ticketLimits?.hasMinQuantity, initialState?.ticketLimits?.hasMinQuantity) || !isEqual(parseInt(ticketLimits?.minQuantity), parseInt(initialState?.ticketLimits?.minQuantity)) || !isEqual(ticketLimits?.hasMaxQuantity, initialState?.ticketLimits?.hasMaxQuantity) || !isEqual(parseInt(ticketLimits?.maxQuantity), parseInt(initialState?.ticketLimits?.maxQuantity)) || !isEqual(ticketLimits?.hasMultipleOfQuantity, initialState?.ticketLimits?.hasMultipleOfQuantity) || !isEqual(parseInt(ticketLimits?.multipleOfQuantity), parseInt(initialState?.ticketLimits?.multipleOfQuantity)) || !isEqual(hasExactLimit, initialState?.hasExactLimit) || !isEqual(parseInt(ticketLimits?.limit), parseInt(initialState?.ticketLimits?.limit)) || (isDiscountedOffer && !isEqual(baseOffer, initialState?.baseOffer)))
    }, [initialState, offer, offer?.name, offer?.description, pricingOpt, priceLevels, availabilityPeriodId, offer?.availabilityPeriods, hasAccessCode, offer?.accessCode, ticketLimits?.hasMinQuantity, ticketLimits?.minQuantity, ticketLimits?.hasMaxQuantity, ticketLimits?.maxQuantity, ticketLimits?.hasMultipleOfQuantity, ticketLimits?.multipleOfQuantity, hasExactLimit, ticketLimits?.limit, isDiscountedOffer, baseOffer])

    useEffect(() => {
        if (hasRequiredFieldError) {
            // if all fields are filled in: dismiss alert 
            if (Object.keys(requiredFieldErrorStatus).every(key => !requiredFieldErrorStatus[key])) {
                closeAlert()
                setHasRequiredFieldError(false)
                setRequiredFieldErrorStatus({})
            }
        }
    }, [hasRequiredFieldError, requiredFieldErrorStatus])

    useEffect(() => {
        // close all alerts when fields are edited except for required fields alert 
        if (alert.show && errors?.length === 0 && !hasRequiredFieldError) {
            closeAlert()
        }
    }, [errors, hasRequiredFieldError])

    useEffect(() => {
        if (hasRequiredFieldError) {
            updateRequiredFieldErrorStatus()
        }
    }, [offer?.name, discount, pricingOpt, hasAccessCode, offer.accessCode, ticketLimits.hasMinQuantity, ticketLimits?.minQuantity, ticketLimits?.hasMaxQuantity, ticketLimits?.maxQuantity, ticketLimits?.hasMultipleOfQuantity, ticketLimits?.multipleOfQuantity, hasExactLimit, ticketLimits?.limit])

    useEffect(() => {
        // change offer price when discount changes
        // only if creating new or editing offer and discount or price levels are not the same as initial state
        if (!isDiscountedOffer && canEdit) {
            if (priceLevels && initialState?.priceLevels) {
                if (!isEqual(parseFloat(discount), parseFloat(initialState?.discount)) || !isPriceSameAsInitial(priceLevels, initialState?.priceLevels)) {
                    let updatedPriceLevels = { ...priceLevels }
                    // always discount off of base price - discount of default price 
                    for (const [key] of Object.entries(initialState?.defaultPriceLevels)) {
                        let price = initialState?.defaultPriceLevels[key].offerPrice || initialState?.defaultPriceLevels[key].price;
                        console.log(price, discount);
                        // not empty
                        if (!isNaN(parseFloat(discount))) {
                            price = getDiscountedPrice(price, pricingOpt, discount)
                            console.log(discount, price);

                            updatedPriceLevels = {
                                ...updatedPriceLevels,
                                [key]: {
                                    ...updatedPriceLevels[key],
                                    offerPrice: price.toString()
                                }
                            }
                        }
                    }
                    setPriceLevels({ ...updatedPriceLevels })
                }
            }
        }
    }, [isDiscountedOffer, canEdit, initialState?.priceLevels, initialState?.discount, discount])

    // reset price levels back to default (Standard Admission offer) every time pricing option changes
    // only if creating new or editing offer and pricing option or offer price is not same as initial state
    useEffect(() => {
        if (!isDiscountedOffer && canEdit) {
            if ((pricingOpt && initialState?.pricingOpt && !isEqual(pricingOpt, initialState?.pricingOpt)) || (Object.keys(priceLevels)?.length > 0 && Object.keys(initialState?.priceLevels)?.length > 0 && !isPriceSameAsInitial(priceLevels, initialState?.priceLevels))) {
                if (initialState?.defaultPriceLevels) {
                    let defaultPriceLevels = { ...priceLevels };
                    setDiscount('')
                    for (const [key, value] of Object.entries(initialState?.defaultPriceLevels)) {
                        const price = value.price;
                        defaultPriceLevels = {
                            ...defaultPriceLevels,
                            [key]: {
                                ...defaultPriceLevels[key],
                                offerPrice: price.toString()
                            }
                        }
                    };

                    setPriceLevels({ ...defaultPriceLevels })

                    // remove input error when input field changes
                    if (findError('discount')) resetError('discount')
                }
            }
        }
    }, [isDiscountedOffer, canEdit, pricingOpt, initialState?.pricingOpt, initialState?.defaultPriceLevels, initialState?.priceLevels])

    // set inventory only if editing 
    useEffect(() => {
        if (isEditing) {
            const inventory = {
                type: currentOffer?.inventoryType.toLowerCase() || 'open',
                color: currentOffer?.color || openInventory?.color,
                seats: eventInventory?.offers[offer.id]?.seats || openInventory?.seats
            }

            setInventory(inventory)
        }
    }, [isEditing, currentOffer])

    // change price level seats everytime inventory changes
    // if not editing offer and inventory is changed
    useEffect(() => {
        if (!isDiscountedOffer && initialState?.priceLevels) {
            if (!isEditing) {
                if (Object.values(inventory)?.length > 0) {
                    let updatedPriceLevels = { ...priceLevels };
                    for (const [key, value] of Object.entries(initialState?.priceLevels)) {
                        updatedPriceLevels = {
                            ...updatedPriceLevels,
                            [key]: {
                                ...updatedPriceLevels[key],
                                seats: inventory?.type === 'open' ? value?.seats : inventory?.seats
                            }
                        }
                    }

                    setPriceLevels({ ...updatedPriceLevels })
                }
            }
        }
    }, [isDiscountedOffer, isEditing, initialState?.priceLevels, inventory])

    // remove input error when input field changes
    useEffect(() => {
        resetError('accessCode')
    }, [offer?.accessCode])

    // remove input errors when input field changes
    useEffect(() => {
        resetError(['minQuantity', 'maxQuantity'])
    }, [ticketLimits?.minQuantity])

    // remove input errors when input field changes
    useEffect(() => {
        resetError(['multipleOfQuantity', 'maxQuantity'])
    }, [ticketLimits?.multipleOfQuantity])

    // max quantity resets all error messages - field is the dependant (all fields depend on this)
    useEffect(() => {
        resetError(['minQuantity', 'maxQuantity', 'multipleOfQuantity'])
    }, [ticketLimits?.maxQuantity])

    // remove input error when input field changes
    useEffect(() => {
        resetError('limit')
    }, [ticketLimits?.limit])

    // check if all offer prices are the same as initial offer prices 
    const isPriceSameAsInitial = (priceLevels, initialPriceLevels) => {
        // Extracting the arrays of offer prices
        const offerPrices = Object.values(priceLevels)?.map(level => level.offerPrice);
        const initialOfferPrices = Object.values(initialPriceLevels)?.map(initialLevel => initialLevel.offerPrice || initialLevel?.price);

        // Check if both arrays have the same elements
        return offerPrices.length === initialOfferPrices.length && offerPrices.every((price, index) => price === initialOfferPrices[index]);
    };

    const updateRequiredFieldErrorStatus = () => {
        setRequiredFieldErrorStatus({
            'name': !offer?.name,
            'discount': ((pricingOpt?.includes('decrease_by') || isDiscountedOffer) && !discount),
            'code': (hasAccessCode && !offer.accessCode),
            'limit': ((ticketLimits?.hasMinQuantity && !ticketLimits?.minQuantity) || (ticketLimits?.hasMaxQuantity && !ticketLimits?.maxQuantity) || (ticketLimits?.hasMultipleOfQuantity && !ticketLimits?.multipleOfQuantity) || (hasExactLimit && !ticketLimits?.limit))
        })
    }

    const scrollToTop = () => {
        return window.scrollTo(0, 0)
    }

    const closeAlert = () => {
        setAlert({ show: false, variant: '', message: '' })
    }

    const handleChange = (e, val = e.target?.value) => {
        if (e.target) setOffer({ ...offer, [e.target.name]: val })
    }

    const handleTicketLimits = (e, val = e.target?.value) => {
        if (e.target) setTicketLimits({ ...ticketLimits, [e.target.name]: val })
    }

    const handleValid = (e) => {
        const { name } = e.target;

        switch (name) {
            case 'discount':
                // always discount off of base price - discount of default price
                if (Object.values(initialState?.defaultPriceLevels)?.some(level => getDiscountedPrice((level?.price || level?.offerPrice), pricingOpt, e.target.value) < 0)) {
                    setErrors([
                        ...errors,
                        {
                            field: name,
                            message: `Discount cannot make offer price go below 0`
                        }
                    ])
                } else {
                    resetError('discount')
                }
                break;

            case 'accessCode':
                if (e.target.value !== "" && !e.target.validity.valid) {
                    setErrors([
                        ...errors,
                        {
                            field: name
                        }
                    ])
                }
                break;

            case 'minQuantity':
                if (ticketLimits?.minQuantity && parseInt(ticketLimits?.minQuantity) > parseInt(ticketLimits?.maxQuantity)) {
                    setErrors([
                        ...errors,
                        {
                            field: name,
                            message: 'Minimum quantity must be less than maximum quantity'
                        }
                    ])
                }
                break;
            case 'maxQuantity':
                if (ticketLimits?.maxQuantity) {
                    if (parseInt(ticketLimits?.maxQuantity) < parseInt(ticketLimits?.minQuantity)) {
                        setErrors([
                            ...errors,
                            {
                                field: name,
                                message: 'Maximum quantity must be greater than minimum quantity'
                            }
                        ])
                    }
                }
                if (ticketLimits?.multipleOfQuantity && (!isMultiple(parseInt(ticketLimits?.maxQuantity), parseInt(ticketLimits?.multipleOfQuantity)))) {
                    setErrors([
                        ...errors,
                        {
                            field: name,
                            message: `Maximum of quantity must be multiple of multiple of quantity (${ticketLimits?.multipleOfQuantity}) (example: ${getMultiples(parseInt(ticketLimits?.multipleOfQuantity))} etc.)`
                        }
                    ])
                }
                break;
            case 'multipleOfQuantity':
                if (ticketLimits?.multipleOfQuantity) {
                    if (ticketLimits?.maxQuantity && !isMultiple(parseInt(ticketLimits?.maxQuantity), parseInt(ticketLimits?.multipleOfQuantity))) {
                        setErrors([
                            ...errors,
                            {
                                field: name,
                                message: `Multiple of quantity must go into maximum quantity (${ticketLimits?.maxQuantity}) evenly (example: ${getDivisors(parseInt(ticketLimits?.maxQuantity))})`
                            }
                        ])
                    }
                    break;
                }
            case 'limit':
                if (ticketLimits?.limit && (parseInt(ticketLimits?.limit) < 1 || parseInt(ticketLimits?.limit) > 50)) {
                    setErrors([
                        ...errors,
                        {
                            field: name,
                            message: 'Ticket limit must be a number between 1 and 50'
                        }
                    ])
                }
            default:
                break;
        }
    }

    const isMultiple = (product, divisor) => {
        return product % divisor === 0;
    }

    const getMultiples = (divisor, limit = 5) => {
        const multiples = [];

        for (let i = 1; i <= limit; i++) {
            multiples.push(divisor * i);
        }

        return multiples;
    }

    const getDivisors = (product) => {
        const divisors = [];

        // loop through 1 to product
        for (let i = 1; i <= product; i++) {
            for (let j = i; j <= product; j++) {
                if (i * j === product) {
                    // if i and j are not the same, add both to array; otherwise just add i 
                    if (i !== j) {
                        divisors.push([i, j]);
                    } else {
                        divisors.push(i)
                    }
                    break; // break out of inner loop when j is a multiplier 
                }
            }
        }
        return divisors;
    };

    // find error in errors array
    const findError = (fields) => {
        const findObj = (field) => {
            return errors?.find(error => error.field === field)
        }

        if (Array.isArray(fields)) {
            return fields?.map(field => findObj(field))?.filter(obj => obj !== undefined)
        } else {
            return findObj(fields)
        }
    }

    // remove error
    const resetError = (fields) => {
        const foundErrors = findError(fields)
        if (Array.isArray(foundErrors)) {
            const filteredErrors = errors.filter(error => !foundErrors.some(obj => obj.field === error.field));
            setErrors(filteredErrors)
        }
        else {
            setErrors(errors?.filter(error => error?.field !== foundErrors?.field))
        }
    }

    const handleGoBack = () => {
        navigate(-1)
    }

    const handleCloseDelete = () => setShowDelete(false)

    // remove offer 
    const handleRemove = () => {
        removeOffers(offer?.uuid).then(() => {
            handleClose();
        })
    }

    // go to seatmap popup 
    const handleShow = (id) => {
        setNewOfferId(id)
        setShow(true)
    }
    const handleClose = () => {
        setShow(false)
        setIsChangesMade(true)
        handleGoBack()
    }

    const handleGoTo = (id) => {
        navigate(`/myevent/${eventId}/seatmap?id=${id}`)
    }


    // change availability periods active property based on selected
    const getUpdatedAvailabilityPeriods = (periods) => {
        console.log(periods, availabilityPeriodId);
        return periods.map(period => {
            return {
                ...period,
                active: period.id === availabilityPeriodId
            };
        });
    }

    const handleSave = (e) => {
        e.preventDefault()

        // if error, don't save 
        if (hasRequiredFieldError) {
            scrollToTop()
            return
        }

        const hasError = (!offer?.name || (hasAccessCode && !offer?.accessCode) || ((pricingOpt?.includes('decrease_by') || isDiscountedOffer) && !discount) || (ticketLimits?.hasMinQuantity && !ticketLimits?.minQuantity) || (ticketLimits?.hasMaxQuantity && !ticketLimits?.maxQuantity) || (ticketLimits?.hasMultipleOfQuantity && !ticketLimits?.multipleOfQuantity) || (hasExactLimit && !ticketLimits?.limit))
        if (hasError) {
            updateRequiredFieldErrorStatus()
            setHasRequiredFieldError(hasError)
            scrollToTop()
            setAlert({
                show: true,
                variant: 'danger',
                message: 'You are missing required subject fields. Please fill out all required fields before continuing.'
            })
            return
        }

        // if other errors 
        if (errors?.length > 0) {
            scrollToTop()
            setAlert({
                show: true,
                variant: 'danger',
                message: 'You have input errors. Please correct all input errors before continuing.'
            })
            return
        }

        setIsSaving(true)

        setIsChangesMade(true)

        // new offer 
        if (!isDiscountedOffer) {

            console.log(getUpdatedAvailabilityPeriods(offer?.availabilityPeriods));
            const data = {};
            data['uuid'] = offer?.uuid;
            data['eventUUID'] = eventId;
            data['name'] = offer?.name;
            data['description'] = offer?.description;
            data['inventoryType'] = inventory?.type;
            data['color'] = inventory?.color;
            data['seats'] = inventory?.seats;
            data['pricing_levels'] = priceLevels;
            data['pricing_option'] = pricingOpt;
            data['discount'] = discount || null;
            data['availability'] = getUpdatedAvailabilityPeriods(offer?.availabilityPeriods);
            data['accessCode'] = offer?.accessCode || null;
            data['hasMinQuantity'] = ticketLimits?.hasMinQuantity;
            data['minQuantity'] = ticketLimits?.hasMinQuantity ? Number(ticketLimits?.minQuantity) : null;
            data['hasMaxQuantity'] = ticketLimits?.hasMaxQuantity;
            data['maxQuantity'] = ticketLimits?.hasMaxQuantity ? Number(ticketLimits?.maxQuantity) : null;
            data['hasMultipleOfQuantity'] = ticketLimits?.hasMultipleOfQuantity;
            data['multipleOfQuantity'] = ticketLimits?.hasMultipleOfQuantity ? Number(ticketLimits?.multipleOfQuantity) : null;
            data['limit'] = Number(ticketLimits?.limit) || null;
            data['soldOut'] = offer?.soldOut

            createOrEditOffers(data)
                .then((res) => {
                    setIsSaving(false)

                    // editing existing offer 
                    if (isEditing) {

                        // save initial state again if editing to check whether to show save buttons
                        saveInitialState(
                            offer,
                            inventory,
                            undefined,
                            priceLevels,
                            pricingOpt,
                            discount,
                            availabilityPeriodId,
                            hasAccessCode,
                            ticketLimits?.hasMinQuantity,
                            ticketLimits?.minQuantity,
                            ticketLimits?.hasMaxQuantity,
                            ticketLimits?.maxQuantity,
                            ticketLimits?.hasMultipleOfQuantity,
                            ticketLimits?.multipleOfQuantity,
                            hasExactLimit,
                            ticketLimits?.limit,
                        )

                        scrollToTop()
                        setAlert({
                            show: true,
                            variant: 'success',
                            message: 'This offer has been updated.'
                        })
                        // creating new offer 
                    } else {
                        if (!isSaving) {
                            if (inventory?.type === 'exclusive') {
                                handleShow(res?.data?.id)
                            } else {
                                handleGoBack()
                            }
                        }
                    }
                })
                .catch((err) => {
                    console.error(err)
                    setIsSaving(false)
                    scrollToTop()
                    setAlert({
                        show: true,
                        variant: 'danger',
                        message: 'Unable to save offer. Please try again.'
                    })
                })
        }
        else {
            const discountData = {
                eventId: eventId,
                name: offer?.name,
                baseOfferId: baseOffer?.id,
                discountPercentage: parseInt(discount, 10),
                expires: baseOffer?.availability.find(period => period.active)?.ends,
                accessCode: offer?.accessCode,
                minTicketQuantity: Number(ticketLimits?.minQuantity) || null,
                maxTicketQuantity: Number(ticketLimits?.maxQuantity) || null,
                multipleOfTicketQuantity: Number(ticketLimits?.multipleOfQuantity) || null,
                ticketLimit: Number(ticketLimits?.limit) || null
            };

            createDiscount(discountData).then(() => {
                handleGoBack()
                setIsSaving(false)
            }).catch((error) => {
                console.error("Error creating discount:", error);
                setIsSaving(false)
            })
        }
    }

    const saveInitialState = (offer, inventory, defaultPriceLevels, priceLevels, pricingOpt, discount, periodId, hasAccessCode, hasMinQuantity, minQuantity, hasMaxQuantity, maxQuantity, hasMultipleOfQuantity, multipleOfQuantity, hasExactLimit, limit, baseOffer) => {
        setInitialState({
            offer,
            inventory,
            defaultPriceLevels,
            priceLevels,
            pricingOpt,
            discount,
            availabilityPeriodId: periodId,
            hasAccessCode,
            hasExactLimit,
            ticketLimits: {
                hasMinQuantity,
                minQuantity,
                hasMaxQuantity,
                maxQuantity,
                hasMultipleOfQuantity,
                multipleOfQuantity,
                limit
            },
            baseOffer
        })
    }

    const getTitle = () => {
        let action = 'Create';

        if (isDiscountedOffer) action = 'Create discounted'
        if (id && canEdit) action = 'Edit'
        else if (!canEdit) action = 'View'

        return `${action} offer`
    }

    return (
        <>
            <div className='position-relative'>
                <section className='event-form'>
                    <Row className='flex-nowrap'>
                        <Col xs={2}>
                            <BackButton size="sm" />
                        </Col>
                        <Col xs={8} className='m-0 p-0 wrapper'>
                            {!isDiscountedOffer && (<WarningCard text="Once this offer is on sale, certain fields cannot be edited. If you require changes, please reach out to your client support representative for assistance" variant="primary" />)}
                            {alert.show &&
                                <>
                                    <Alert variant={alert.variant} className="mb-5">
                                        <p>{alert.message}</p>
                                    </Alert>
                                </>
                            }
                            <header className="section-header-sm section-heading section-heading--secondary">
                                <h1>{getTitle()}</h1>
                            </header>
                            <Form onSubmit={handleSave}>
                                <Card body className='card--light card--md'>
                                    <Details
                                        isStandard={isStandard}
                                        canEdit={canEdit}
                                        offer={offer}
                                        isDiscountedOffer={isDiscountedOffer}
                                        discount={discount}
                                        setDiscount={setDiscount}
                                        requiredFieldErrorStatus={requiredFieldErrorStatus}
                                        handleChange={handleChange}
                                    />
                                </Card>
                                {isDiscountedOffer && (
                                    <Card body className='card--light card--md'>
                                        <OpenOffers
                                            offers={offers?.filter(offer => offer.inventoryType === 'open')}
                                            baseOffer={baseOffer}
                                            setBaseOffer={setBaseOffer}
                                            eventTimezone={event?.timezone}
                                            getAvailabilityPeriods={getAvailabilityPeriods}
                                        />
                                    </Card>
                                )}
                                {!isDiscountedOffer && (
                                    <>
                                        <Card body className='card--light card--md'>
                                            <Inventory
                                                isEditing={isEditing}
                                                offerName={offer?.name}
                                                openInventory={openInventory}
                                                inventory={inventory}
                                                setInventory={setInventory}
                                            />
                                        </Card>
                                        <Card body className="card--light card--md">
                                            <PricingFees
                                                opt={pricingOpt}
                                                setOpt={setPricingOpt}
                                                discount={discount}
                                                setDiscount={setDiscount}
                                                levels={priceLevels}
                                                setLevels={setPriceLevels}
                                                inventory={inventory}
                                                standardAdmissionOfferHasInventory={standardAdmissionOfferHasInventory}
                                                canEdit={canEdit}
                                                isEditing={isEditing}
                                                taxRates={taxRates}
                                                fees={fees}
                                                isStandard={isStandard}
                                                requiredFieldErrorStatus={requiredFieldErrorStatus}
                                                errors={errors}
                                                findError={findError}
                                                handleValid={handleValid}
                                            />
                                        </Card>
                                    </>
                                )}
                                {isStandard && (
                                    <Card body className="card--light card--md">
                                        <PricingLevelDescription
                                            priceLevels={priceLevels}
                                            setPriceLevels={setPriceLevels}
                                            isDisabled={offer?.status === 'ended'}
                                        />
                                    </Card>
                                )}
                                {!isDiscountedOffer && (
                                    <Card body className="card--light card--md">
                                        <Availability
                                            openOffers={offers?.filter(offer => offer.inventoryType === 'open')}
                                            isStandard={isStandard}
                                            event={event}
                                            offers={offers}
                                            offer={offer}
                                            setOffer={setOffer}
                                            selected={availabilityPeriodId}
                                            setSelected={setAvailabilityPeriodId}
                                            isEditing={isEditing}
                                            canEdit={canEdit}
                                        />
                                    </Card>
                                )}
                                {!isStandard && (
                                    <>
                                        <Card body className="card--light card--md">
                                            <AccessCode
                                                offer={offer}
                                                setOffer={setOffer}
                                                errors={errors}
                                                canEdit={canEdit}
                                                initialState={initialState?.offer?.accessCode}
                                                setHasAccessCode={setHasAccessCode}
                                                requiredFieldErrorStatus={requiredFieldErrorStatus}
                                                handleChange={handleChange}
                                                handleValid={handleValid}
                                                findError={findError}

                                            />
                                        </Card>
                                        <Card body className="card--light card--md">
                                            <TicketLimits
                                                globalTicketLimit={event?.globalTicketLimit}
                                                limits={ticketLimits}
                                                setLimits={setTicketLimits}
                                                initialState={initialState?.ticketLimits}
                                                errors={errors}
                                                requiredFieldErrorStatus={requiredFieldErrorStatus}
                                                canEdit={canEdit}
                                                setHasExactLimit={setHasExactLimit}
                                                handleChange={handleTicketLimits}
                                                handleValid={handleValid}
                                                findError={findError}

                                            />
                                        </Card>
                                    </>
                                )}
                            </Form>
                            {(isEditing && canEdit && !isStandard) && (
                                <Button
                                    variant="link"
                                    className="mt-4 text-danger icon-button btn-delete icon-button--danger"
                                    onClick={() => setShowDelete(true)}
                                >Delete this offer</Button>
                            )}
                        </Col>
                    </Row>
                    {showFooter && (
                        <CreateEventButtons
                            isEditing={id}
                            page="Offers"
                            isDiscountedOffer={isDiscountedOffer}
                            showGoBack={true}
                            isSaving={isSaving}
                            handleGoBack={handleGoBack}
                            handleSave={handleSave}
                        />
                    )}
                </section>
            </div >

            <GoToSeatMapModal show={show} id={newOfferId} handleClose={handleClose} handleClick={handleGoTo} />

            <DeleteModal show={showDelete} entity="offer" isRemoving={isRemoving} handleDelete={handleRemove} handleClose={handleCloseDelete} />
        </>
    );
}