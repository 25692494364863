import React from 'react';

import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import Stack from 'react-bootstrap/Stack';
import Button from 'react-bootstrap/Button';

import { InfoIcon } from '../../../../InfoIcon';

export default function AddDescriptionModal({ show, isEditing, description, setDescription, isDisabled, handleClose, handleSubmit }) {

    return (
        <Modal centered animation={false} fullscreen="md-down" show={show} onHide={handleClose}>
            <Modal.Body>
                <div className="modal-body-heading">
                    <h1 className="modal-body-heading-title">Pricing level description</h1>
                    <small className='subtitle subtitle--dark'>This information will be shown on each pricing level. Make sure they accurately describe the ticket information</small>
                </div>
                <Form onSubmit={handleSubmit}>
                    <Form.Group className="form-group" controlId="description">
                        <div className="form-label--flex">
                            <Form.Label>Description</Form.Label>
                            <OverlayTrigger
                                placement="right"
                                overlay={<Tooltip>Add information about this pricing level</Tooltip>}>
                                <Button variant="link">
                                    <InfoIcon />
                                </Button>
                            </OverlayTrigger>
                        </div>
                        <Form.Control
                            as="textarea"
                            rows={5}
                            name="description"
                            placeholder="Add description for this pricing level"
                            value={description}
                            onChange={(e) => setDescription(e.target.value)}
                            autoFocus
                        />
                    </Form.Group>
                    <Stack direction="horizontal" className='btn-group-flex'
                    >
                        <Button variant="outline-light"
                            size="lg"
                            onClick={handleClose}>Cancel</Button>
                        <Button
                            disabled={!description || isDisabled}
                            type="submit"
                            size="lg"
                        >
                            {isEditing ? 'Update' : 'Apply'}
                        </Button>
                    </Stack>
                </Form>
            </Modal.Body>
        </Modal>

    );
}
