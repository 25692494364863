import React, { useState } from 'react'

import { formatCurrency } from '../../../../../../../utilities/helpers'

import Button from 'react-bootstrap/Button'

import { DroppableItem } from '../../DroppableItem';
import { Item } from '../../Item';

export default function PriceLevel({ canEdit, level, levels, handleShow, moveFromIds, sumRevenue, handleRemove }) {

    const { id, price } = level;

    const [hasHover, setHasHover] = useState(false)

    return (
        <DroppableItem index={id.toString()} data={levels} moveFromIds={moveFromIds} hasNoPrice={!level.price} styles="d-flex flex-column gap-2" hasHover={canEdit}>
            <Item index={id} el={level} data={levels} canRemove={Boolean(handleRemove) && canEdit} handleRemove={handleRemove}>
                <div className="split-row">
                    <div className="flex gap-4">
                        <span>Base price </span>
                        <Button
                            variant='link'
                            onMouseEnter={() => setHasHover(true)}
                            onMouseLeave={() => setHasHover(false)}
                            onClick={(e) => handleShow(e, level)}
                            disabled={!canEdit}
                        >

                            {price ? (
                                <>
                                    <span style={{ display: `${hasHover ? 'none' : 'block'}` }} className="text-body">{formatCurrency(price)}</span>
                                    <span style={{ display: `${hasHover ? 'block' : 'none'}` }} className='text-link text-link--thin'>Edit value</span>
                                </>
                            ) : (
                                <span className='text-link text-link--thin'>Add value</span>
                            )}
                        </Button>
                    </div>
                    <span>{price ? formatCurrency(sumRevenue(level)) : formatCurrency(0)} </span>
                </div>
            </Item>
        </DroppableItem>
    )
}