import React from "react";

import { formatShortDate, getTimezoneDate } from "../../../utilities/helpers";

import Card from "react-bootstrap/Card";
import Stack from "react-bootstrap/Stack";
import Badge from "react-bootstrap/Badge";

export default function Discounts({ discounts, offers, eventTimezone, getAvailabilityPeriods }) {

    // get end date from parent offer in case it changes 
    const getParentOfferEndSale = (discountObj) => {
        const parentOffer = offers?.find(offer => offer?.id === discountObj?.offerId)

        // if parent offer in offers 
        const updatedAvailabilityPeriods = parentOffer?.name !== 'Standard Admission' ? getAvailabilityPeriods(parentOffer) : parentOffer?.availability
        const updatedOffer = { ...parentOffer, availability: updatedAvailabilityPeriods }
        console.log(updatedOffer);
        const selectedPeriod = updatedOffer?.availability.find(period => period.active)

        console.log(parentOffer);

        return formatShortDate(getTimezoneDate(selectedPeriod.ends, eventTimezone))
    }
    return (
        <Card body className='card--md card--light'>
            <div className='card-body-heading card-body-heading--xs'>
                <Card.Title as='h5' className='text-upper mb-3'>
                    Discounts
                </Card.Title>
            </div>
            {(!discounts || discounts?.some(obj => obj.discounts.length > 0)) ? (
                <Stack gap={3} as='ul'>
                    {discounts && discounts?.map((discountObj, idx) => (
                        <div key={idx}>
                            {discountObj.discounts.map((discount, idx) => (
                                <Card body as="li" key={idx}>
                                    <div className="d-flex justify-content-between">
                                        <Card.Title as="h5" className="text-primary">{discount?.name}</Card.Title>
                                        <Badge
                                            bg="primary"
                                        >
                                            {offers?.find(offer => offer.id === discountObj.offerId)?.name}
                                        </Badge>
                                    </div>
                                    <Stack gap={1} className="fw-semi-bold small">
                                        <p><span className="caption text-muted">Discount: </span>{discount?.discountPercentage}%</p>
                                        {discount?.accessCode && (
                                            <p><span className="caption text-muted">Access Code: </span>{discount?.accessCode}</p>
                                        )}
                                        {discount?.minTicketQuantity && (
                                            <p><span className="caption text-muted">Minimum Ticket Quantity: </span>{discount?.minTicketQuantity}</p>
                                        )}
                                        {discount?.maxTicketQuantity && (
                                            <p><span className="caption text-muted">Maximum Ticket Quantity: </span>{discount?.maxTicketQuantity}</p>
                                        )}
                                        {discount?.multipleOfTicketQuantity && (
                                            <p><span className="caption text-muted">Multiple Of Ticket Quantity: </span>{discount?.multipleOfTicketQuantity}</p>
                                        )}
                                        {discount?.ticketLimit && (
                                            <p><span className="caption text-muted">Ticket Limit: </span>{discount?.ticketLimit}</p>
                                        )}
                                        <p><span className="caption text-muted">Expires: </span>{getParentOfferEndSale(discountObj)}</p>
                                    </Stack>
                                </Card>
                            ))}
                        </div>
                    ))}
                </Stack>
            ) : (
                <p className='text-muted mb-0'>
                    No discounts available for this event.
                </p>
            )}
        </Card>
    );
}
