import { useEffect, useLayoutEffect, useState, useContext } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';

import EventDetailsContext from '../../../context/EventDetails/EventDetails';

import { fullHeightContainer, removeFullHeightContainer } from '../../../utilities/helpers'

import { createorEditTemplate, assignPricingLevelsToOffer, assignSeatmapToEvent, createAvailabilityMapping } from '../../../utilities/api';

import { useInventory } from '../InventoryProvider/InventoryProvider';
import { Navigation } from './Navigation'
import { ResetMapModal } from './ResetMapModal'
import { ResetConfigurationModal } from './ResetConfigurationModal'
import { MapConfigurations } from './MapConfigurations';
import { SelectMapModal } from "../SelectMapModal";
import { ConfirmTemplateModal } from './ConfirmTemplateModal';
import { SaveModal } from './SaveModal';
import { ConfirmSaveTemplateModal } from './ConfirmSaveTemplateModal';
import { PageLoadingContainer } from '../../PageLoadingContainer';

export default function ConfigureMap({ eventId, offerId, event, data, setData, background, seatmaps, templates, setTemplates, isTemplate, setIsTemplate, activeTab, setActiveTab, selectedMapId, setSelectedMapId, configuration, template, setTemplate, isReloading, setIsReloading, loadEvent, reloadData }) {

    const location = useLocation();
    const navigate = useNavigate();

    const { isEventOnsale, isEventPublished, setStandardAdmissionOfferHasInventory, updateEvent } = useContext(EventDetailsContext)

    const { setHolds, setKills, setSold, holds, kills, soldColor, exclusiveOffers, setExclusiveOffers } = useInventory();

    // This is actually inventories, perhaps offerInventories
    // all offers 
    const [offers, setOffers] = useState({})

    // unassigned seats for scaling menu to be able to display in move seats popup when deleting pricing level 
    const [unassignedSeats, setUnassignedSeats] = useState({});

    const [priceLevels, setPriceLevels] = useState({})

    const [initialSelectedId, setInitialSelectedId] = useState()

    // local state of selectedMapId in case of reverting back to original value
    const [selectedId, setSelectedId] = useState(null)

    // array of all seats 
    const [totalSeats, setTotalSeats] = useState([]);

    // array of all GA seats 
    const [totalGASeats, setTotalGASeats] = useState([]);

    const [showResetConfiguration, setShowResetConfiguration] = useState(false)

    const [showReset, setShowReset] = useState(false);

    const [choice, setChoice] = useState(null);

    const [show, setShow] = useState(false);

    const [showConfirmTemplate, setShowConfirmTemplate] = useState(false)

    const [confirmTemplateStep, setConfirmTemplateStep] = useState(1)

    const [templateName, setTemplateName] = useState('')

    const [showSave, setShowSave] = useState(false)

    const [saveStep, setSaveStep] = useState(1)

    const [isSaving, setIsSaving] = useState(false)

    const [isConfigSaved, setIsConfigSaved] = useState(false)

    const [showConfirmSave, setShowConfirmSave] = useState(false)

    // array of all seats assigned to price levels from template or session 
    let assignedSeatsToPriceLevels = []
    // array of all seats assigned to holds/kills/exclusive offers from template or session 
    let assignedSeatsToHoldsKillsOffers = [];

    // set html and body to full height
    useLayoutEffect(
        () => {

            [
                'html',
                'body'
            ].forEach((el) => document.querySelector(el).classList.add('full-height'));


            return () => {
                [
                    'html',
                    'body'
                ].forEach((el) => document.querySelector(el).classList.remove('full-height'));
            };
        },
        [
            location.pathname
        ]
    );

    useLayoutEffect(() => {
        // hide navbar
        const nav = document.getElementById('navbar')
        nav.style.display = "none"
        // hide event banner
        const banner = document.getElementById('banner')
        banner.style.display = "none"
        // hide event banner
        const sidebarNav = document.getElementById('sidebarMenu')
        sidebarNav.style.display = "none"

        // modify sidebar container
        const container = document.getElementById('main-container')
        container.classList.add('sidebar-container-lg')
        fullHeightContainer(container);

        // modify container
        const content = document.getElementById('main-content')
        content.classList.add('pb-0')
        content.classList.add('full-height-wrapper')

        return () => {
            nav.style.display = ""
            banner.style.display = ""
            sidebarNav.style.display = ""
            container.classList.remove('sidebar-container-lg')
            removeFullHeightContainer(container)
            content.classList.remove('pb-0')
            content.classList.remove('full-height-wrapper')
        }
    }, [])

    // set total seats and total ga seats 
    // pass unassigned seats and open offer to getConfiguration to set once
    useEffect(() => {
        let updatedData = { ...data }

        let totalSeats = []

        if (data?.seats) {
            Object.values(updatedData?.seats).forEach((seat) => {
                if (!seat.PLId && !seat.selected) {
                    totalSeats.push(seat.seatId);
                }
            });
        }

        if (data?.sections) {
            // Account for GA
            let GAFound = false;
            Object.values(updatedData?.sections).forEach((section) => {
                if (!section?.zoomable && !section?.selected && !GAFound) { // Perhaps will have issues with selected
                    GAFound = true;
                    // add index to ga tickets to make them unique so open seats can filter out selected seats properly

                    const gaSeats = section?.spots;
                    setTotalGASeats(gaSeats)
                    totalSeats.push(...gaSeats);
                }
            })
        }

        // set unassigned seats
        const unassignedSeats = {
            1: {
                ...getDefaultObj(1, "Unassigned", '#e6e8ec', totalSeats)
            }
        }

        // set open offer (Standard Admission etc.)
        const standardOffer = Object.values(event?.offers)[0]
        const id = standardOffer?.id || 1;
        const openOffer = {
            [id]: {
                ...getDefaultObj(id, "Open", '#3ea9f7', standardOffer?.seats),
                subOffers: event?.offers?.length > 0 ? event?.offers?.slice(1)?.filter(offer => offer.inventoryType === 'open').map(offer => {
                    return {
                        ...getDefaultObj(offer?.id, offer?.name, '#3ea9f7', standardOffer?.seats)
                    }
                }) : []
            }
        }

        setTotalSeats(totalSeats)

        const getMapConfiguration = (sessionKey, template, assignKey, setState) => {
            getConfiguration(sessionKey, template, assignKey, setState, event, totalSeats, unassignedSeats, openOffer);
        }

        // populate seatmap and state from sessions if not a template  
        if (!isTemplate) {
            getMapConfiguration('priceLevels', undefined, 'pl', setPriceLevels);
            getMapConfiguration('holds', undefined, 'hold', setHolds);
            getMapConfiguration('kills', undefined, 'kill', setKills);
            getMapConfiguration(undefined, undefined, 'offer', setExclusiveOffers);
            getMapConfiguration(undefined, undefined, 'sold', setSold);
        }
    }, [])

    useEffect(() => {
        if (offerId) setActiveTab('inventory')
    }, [offerId])

    // set to selected map id
    useEffect(() => {
        const selectedId = selectedMapId || null
        setSelectedId(selectedId)
        setInitialSelectedId(selectedId)
    }, [selectedMapId])

    useEffect(() => {
        const setMapFromTemplate = async (template) => {

            // change current configuration when changing templates
            if (template && template?.mapping) {
                await unassignAll(template?.mapping); // async await now just to avoid sideEffects, improve later
                getConfiguration(undefined, template, 'pl', setPriceLevels, event, totalSeats)
                // TODO: get configuration for holds/kills 
                // getConfiguration(undefined, template, 'holds', setHolds, event, totalSeats)
                // getConfiguration(undefined, template, 'kills', setKills, event, totalSeats)
            }
        }
        setMapFromTemplate(template);
    }, [template])

    useEffect(() => {
        // save price levels/holds/kills/offers in session
        sessionStorage.setItem('priceLevels', JSON.stringify(priceLevels))
        sessionStorage.setItem('holds', JSON.stringify(holds))
        sessionStorage.setItem('kills', JSON.stringify(kills))
        setIsConfigSaved(true)
    }, [priceLevels, holds, kills])

    useEffect(() => {
        // Set up an interval to update state every 3 seconds
        const intervalId = setInterval(() => {
            setIsConfigSaved(false);
        }, 3000); // 3000 milliseconds = 3 seconds

        // Clear the interval when the component is unmounted
        return () => clearInterval(intervalId);

    }, [])

    // update exclusive offer state whenever offers state changes
    useEffect(() => {
        // update only exclusive offers
        // remove first offer
        const updatedOffersCopy = { ...offers }
        const firstKey = Object.keys(updatedOffersCopy)[0]
        delete updatedOffersCopy[firstKey]
        setExclusiveOffers(updatedOffersCopy)
    }, [offers])

    // update seat map and set price levels/holds/kills/exclusive offers from sessions/template/event
    const getConfiguration = (sessionKey, template, assignKey, setState, event, totalSeats, unassignedSeats, openOffer) => {
        let data;
        let offers = {}

        // get from state sessions
        if (sessionKey) {
            data = JSON.parse(sessionStorage.getItem(sessionKey))
        }
        // get state from template
        if (template) {
            switch (assignKey) {
                case 'pl':
                    data = template?.pricingLevels;
                    break;

                case 'hold':
                    data = template?.holds
                    break;

                case 'kill':
                    data = template?.kills
                    break

                default:
                    break;
            }
        }
        // if no data from session or template get state from event
        if (!data) {
            switch (assignKey) {
                case 'pl':
                    data = event?.pricingLevels
                    break;

                case 'hold':
                    data = event?.inventory?.holds
                    break;

                case 'kill':
                    data = event?.inventory?.kills
                    break

                case 'offer':
                    // get exclusive offers 
                    // get offer seats from event inventory since newly updated 
                    let exclusiveOffers = {};
                    event?.offers.slice(1).filter((offer) => offer.inventoryType === 'exclusive').map(offer => {
                        exclusiveOffers = {
                            ...exclusiveOffers,
                            [offer?.id]: {
                                ...getDefaultObj(offer?.id, offer?.name, offer?.color, event?.inventory?.offers[offer?.id]?.seats)
                            }
                        }
                    })
                    data = { ...(Object.keys(exclusiveOffers)?.length > 0 && { ...exclusiveOffers }) }
                    offers = { ...openOffer, ...data }
                    break;

                case 'sold':
                    // get sold seats
                    data = {
                        1: {
                            id: 1,
                            name: 'Sold',
                            color: soldColor,
                            seats: getSoldSeats(event)
                        }
                    }
                default:
                    break;
            }
        }

        if (data) {
            Object.values(data).forEach((item) => {
                assignSeatsTo(item, item?.seats, assignKey);
                if (assignKey === 'pl') {
                    // only do this if setting price level configurations
                    assignedSeatsToPriceLevels.push(...item.seats)
                }
                if (assignKey === 'hold' || assignKey === 'kill' || assignKey === 'offer') {
                    // only do this if setting hold or kill or exclusive offer configurations
                    assignedSeatsToHoldsKillsOffers.push(...item.seats)
                }
            });

            // only filter unassigned seats and open offer if adding configurations to price levels/holds/kills/exclusive offers
            if (assignKey === 'pl' || assignKey === 'hold' || assignKey === 'kill' || assignKey === 'offer') {
                // update unassign seats and open offer 
                assignFilteredSeats(totalSeats, assignedSeatsToPriceLevels, assignedSeatsToHoldsKillsOffers, unassignedSeats, offers, event)
            }
            setState(data);
        }
    }

    // filter out seats that are already assigned 
    const getFilteredSeats = (totalSeats, assignedSeats) => {
        return totalSeats?.filter(seat => !assignedSeats.includes(seat));
    }

    // update unassign seats and open offer 
    const assignFilteredSeats = (totalSeats, priceLevelSeats, holdKillOfferSeats, unassignedSeats, offers, event) => {
        let id;
        // get seats that are leftover after assigned to price levels 
        let filteredUnassignedSeats = []
        filteredUnassignedSeats = getFilteredSeats(totalSeats, priceLevelSeats)
        id = Object.keys(unassignedSeats)[0]
        // set unassigned seats
        setUnassignedSeats({
            ...unassignedSeats,
            [id]: {
                ...unassignedSeats[id],
                seats: filteredUnassignedSeats
            }
        })

        const filteredOpenSeats = getOpenInventory(totalSeats, filteredUnassignedSeats, holdKillOfferSeats, event, priceLevelSeats)

        id = Object.keys(offers)[0]

        // set open offer
        setOffers({
            ...offers,
            [id]: {
                ...offers[id],
                seats: filteredOpenSeats
            }
        })
    }

    // get sold seats - are sold out and not a hold and not a kill 
    const getSoldSeats = (event) => {
        return event?.tickets?.filter(ticket => ((ticket?.on_sale_status === 'soldout' && !ticket?.hold && !ticket?.kill)))?.map(ticket => ticket.name)
    }

    // get open seats, removing inventory that is already assigned
    const getOpenInventory = (totalSeats, unassignedSeats, holdKillOfferSeats, event, priceLevelSeats) => {
        // get all sold out seats 
        const soldOpenSeats = getSoldSeats(event)
        // return unassigned seats if no price levels seats (means no price levels are set = inventory not set)
        // else return all unassigned and sold seats (there is open inventory) 
        const filteredSeats = priceLevelSeats?.length === 0 ? unassignedSeats : [...unassignedSeats, ...soldOpenSeats];
        // remove seats that are already assigned to inventory
        // totalSeats = seats that are not unassigned or seats that are not unassigned and seats that are not sold out 
        return getFilteredSeats(totalSeats.filter(seat => !filteredSeats.includes(seat)), holdKillOfferSeats)
    }

    // Before setting everything to a template 
    // Need to clean the seatmap
    // Because assignSeatsTo only assigns
    // This still does not account for holds and kills
    const unassignAll = async (data) => {
        const updatedData = { ...data };

        // select all seats
        Object.values(updatedData.seats).forEach((seat) => {
            if (seat.PLId) {
                seat.selected = false;
                seat.PLId = undefined;
                seat.PLFill = undefined;
            }
        });
        // Account for GA
        Object.values(updatedData.sections).forEach((section) => {
            if (!section?.zoomable && !section?.selected) {
                if (section.PLId) {
                    section.selected = false;
                    section.PLId = undefined;
                    section.PLFill = undefined;
                }
            }
        })
        setData(updatedData);
    }

    const assignSeatsTo = (obj, seatIds, whatToAssign) => {
        let toUpdateInSeatOrSection;
        const newSeatIds = [...getSeatIds(seatIds)]
        switch (whatToAssign) {
            case 'pl':
                toUpdateInSeatOrSection = { PLId: obj.id, PLFill: obj.color, selected: false };
                break;
            case 'hold':
                toUpdateInSeatOrSection = { killId: undefined, holdId: obj.id, offerId: undefined, inventoryFill: obj.color, selected: false };
                break;
            case 'kill':
                toUpdateInSeatOrSection = { holdId: undefined, killId: obj.id, offerId: undefined, inventoryFill: obj.color, selected: false };
                break;
            case 'offer':
            case 'sold':
                toUpdateInSeatOrSection = { holdId: undefined, killId: undefined, offerId: obj.id, inventoryFill: obj.color, selected: false };
                break;
            default:
                break;
        }

        if (!toUpdateInSeatOrSection) return;
        sharedAssignRemove(toUpdateInSeatOrSection, newSeatIds);
    }

    const removeSeatsFrom = (seatIds, whatToRemove) => {
        let toUpdateInSeatOrSection;
        const newSeatIds = [...getSeatIds(seatIds)]
        switch (whatToRemove) {
            case 'pl':
                toUpdateInSeatOrSection = { PLId: undefined, PLFill: undefined, selected: false };
                break;
            case 'hold':
                toUpdateInSeatOrSection = { holdId: undefined, inventoryFill: undefined, selected: false };
                break;
            case 'kill':
                toUpdateInSeatOrSection = { killId: undefined, inventoryFill: undefined, selected: false };
                break;
            case 'offer':
                toUpdateInSeatOrSection = { inventoryFill: undefined, selected: false };
                break;
            default:
                break;
        }

        if (!toUpdateInSeatOrSection) return;
        sharedAssignRemove(toUpdateInSeatOrSection, newSeatIds);
    }

    // if seats are ga - get its section id
    // else return seat id
    const getSeatIds = (ids) => {
        if (ids?.filter(id => id.startsWith('ga').length > 0)) {
            // replace all ga seats with its section id 
            return ids?.map(id => id.startsWith("ga") ? id.substring(3, 4) : id);
        } else return ids
    }

    const sharedAssignRemove = (objectToAssign, ids) => {
        const assignedRows = new Set();
        const updatedData = { ...data };

        ids.forEach((id) => {
            let seatOrSection = updatedData.seats[id] || updatedData.sections[id];
            if (!seatOrSection) return

            // Add row to assignedRows for later checking
            if (seatOrSection?.rowId && !assignedRows.has(seatOrSection.rowId)) {
                assignedRows.add(seatOrSection.rowId);
            }

            // Assign
            const updatedSeatOrSection = { ...seatOrSection, ...objectToAssign };

            if (updatedData.seats[id]) {
                updatedData.seats[id] = updatedSeatOrSection;
            } else {
                updatedData.sections[id] = updatedSeatOrSection;
            }
        })

        // Check allAssigned status of all rows effected
        areRowsAssigned(updatedData, Array.from(assignedRows));
        // Save
        setData(updatedData);
    }

    const areRowsAssigned = (updatedData, rowIds) => {
        rowIds.forEach((rowId) => {
            const row = updatedData.rows[rowId];
            let allAssigned = true;
            let inventoryAllAssigned = true;

            for (const seatId of row.seats) {
                const seat = updatedData.seats[seatId];

                if (seat.PLId === undefined) {
                    allAssigned = false;
                }
                if (seat.inventoryFill === undefined) {
                    inventoryAllAssigned = false;
                }

                // If both conditions are met, no need to check other seats
                if (!allAssigned && !inventoryAllAssigned) {
                    break;
                }
            }

            // Assign the values after the loop
            row.allAssigned = allAssigned;
            row.inventoryAllAssigned = inventoryAllAssigned;
        })
    }

    // object for all scaling/inventory
    const getDefaultObj = (id, name, color, seats) => {
        const updatedSeats = seats ?? []
        return { id, name, color, seats: updatedSeats }
    }

    const resetConfiguration = () => {
        setPriceLevels({})
        setHolds({})
        setKills({})

        // change tab to scaling
        setActiveTab("scaling")

        // remove seatmap colours from sessions
        const reset = (sessionKey, whatToRemove, offers) => {
            const data = JSON.parse(sessionStorage.getItem(sessionKey));
            if (data) {
                Object.values(data).forEach((item) => {
                    removeSeatsFrom(item?.seats, whatToRemove);
                });
            }

            if (offers) {
                // remove seatmap colours from custom offers 
                Object.values(exclusiveOffers).forEach(offer => {
                    removeSeatsFrom(offer?.seats, 'offer')
                })
            }
        }

        reset('priceLevels', 'pl');
        reset('holds', 'hold');
        reset('kills', 'kill');
        if (Object.keys(exclusiveOffers)?.length > 0) {
            reset(undefined, 'offer', exclusiveOffers);
        }

        // TODO: reset holds and kills in template
        if (template) {
            setTemplate({ ...template, pricingLevels: {} })
        }
        // reset offers and unassigned seats to totalSeats
        setUnassignedSeats(prevState => ({
            ...prevState,
            1: {
                ...prevState[1],
                seats: totalSeats
            }
        }))
        const id = Object.values(event?.offers)[0]?.id

        // set open offer
        const defaultOpenOffer = {
            [id]: {
                ...offers[id],
                seats: []
            }
        }

        // reset all custom seats to empty 
        let defaultCustomOffers = {}

        Object.keys(offers).slice(1).map(key => {
            defaultCustomOffers = {
                ...defaultCustomOffers,
                [key]: {
                    ...offers[key],
                    seats: []
                }
            }
        })

        setOffers({ ...defaultOpenOffer, ...defaultCustomOffers })
    }

    // reset configuration modal
    const handleShowResetConfiguration = () => setShowResetConfiguration(true);

    const handleCloseResetConfiguration = (_, choice) => {
        setShowResetConfiguration(false)
        // show select map modal
        if (choice) setShow(true)
    }

    // reset map modal - reset or select map
    const handleClick = (e, choice) => {
        if (choice) {
            setChoice(choice)
            handleCloseResetConfiguration(e, choice)
        } else {
            setShowResetConfiguration(false)
            // reset map
            setShowReset(true)
        }
    }

    // reset map modal
    const handleReset = () => {
        // reset - undo changes made to seatmap 
        resetConfiguration()
        setShowReset(false)
    }

    const handleCloseReset = () => setShowReset(false)

    const handleSubmit = (isTemplate = false) => {
        if (isTemplate) {
            handleClose()
            setShowConfirmTemplate(true)
        }
        // new map
        else {
            setIsSaving(true)
            let data = {
                eventUUID: event.uuid,
                seatMapID: Number(selectedId)
            }
            // add selected seatmap to event 
            assignSeatmapToEvent(data)
                .then(() => {
                    setIsSaving(false)
                    handleClose()
                    resetConfiguration()
                    setIsTemplate(false)
                    sessionStorage.setItem('isTemplate', JSON.stringify(false))
                    reloadData()
                })
                .catch((err) => {
                    console.error(err)
                    setIsSaving(false)
                })
        }
    }

    const handleCancel = () => {
        setSelectedId(initialSelectedId)
        handleClose();
    }

    // select map modal
    const handleClose = () => {
        setChoice(null)
        setShow(false)
    }

    // confirm template modal 
    const handleSubmitConfirmTemplate = () => {
        setIsSaving(true)
        let data = {
            eventUUID: event.uuid,
            seatMapID: Number(selectedId)
        }
        // add selected seatmap to event 
        assignSeatmapToEvent(data)
            .then(() => {
                setIsSaving(false)
                setConfirmTemplateStep(2)
                setIsTemplate(true)
                resetConfiguration()
                sessionStorage.setItem('isTemplate', true)
                reloadData()
            })
            .catch((err) => {
                console.error(err)
                setIsSaving(false)
            })
    }

    const handleCloseConfirmTemplate = () => {
        setShowConfirmTemplate(false)
        setConfirmTemplateStep(1)
    }

    // save modal
    const handleShowSave = () => {
        // if not a template, save template 
        if (!isTemplate) setSaveStep(2)
        setShowSave(true)
    }

    // save as template
    const handleSubmitSave = (e) => {
        e.preventDefault()
        handleSave();
    }

    // save as new template or save changes to existing template
    const handleSave = (editMode = false) => {
        setIsSaving(true)
        // save data 
        // TODO: save holds and kills in template 
        let mapId = sessionStorage.getItem('mapId')
        mapId = JSON.parse(mapId)
        let pricingLevels = getPriceLevels()
        let holds = sessionStorage.getItem('holds')
        holds = JSON.parse(holds)
        let kills = sessionStorage.getItem('kills')
        kills = JSON.parse(kills)
        let data = {
            name: templateName || template?.name,
            seatmap: Number(mapId),
            eventUUID: event.uuid,
            pricingLevels,
            editMode
        }

        createorEditTemplate(data)
            .then((res) => {
                setIsSaving(false)
                handleCloseSave()
                setShowConfirmSave(true)
                setIsTemplate(true)
                sessionStorage.setItem('isTemplate', true)
                setSelectedMapId(res?.data?.id)
                sessionStorage.setItem('mapId', JSON.stringify(res?.data?.id))
                setTemplates([...templates, res.data])
            })
            .catch((err) => {
                console.error(`Template save error: ${err}`)
                setIsSaving(false)
            })
    }

    const handleCloseSave = () => {
        setShowSave(false)
        setSaveStep(1)
    }

    const handleCancelSave = () => {
        handleCloseSave()
        setTemplateName('')
    }

    // confirm save as template modal 
    const handleCloseConfirmSave = () => {
        setShowConfirmSave(false)
        setTemplateName('')
    }

    // Saves price levels/inventory to offers
    // assigns to Standard Admission offer 
    // assigns to custom offer if offer id is passed 
    const handleSaveToOffer = () => {
        return new Promise((resolve, reject) => {
            setIsSaving(true)
            // get data 
            const offerData = {
                eventUUID: eventId,
                pricingLevels: getPriceLevels(),
                inventory: {
                    offers,
                    holds,
                    kills
                },
                hotspotDetails: data.seats,
                seatmapJSON: data
            }

            console.log('createAvailabilityMapping: ', offerData.inventory);
            console.log('createAvailabilityMapping: ', offerData.pricingLevels);
            createAvailabilityMapping(offerData);
            // assign pricing levels and inventory
            assignPricingLevelsToOffer(offerData)
                .then(() => {
                    resolve()
                })
                .catch((err) => {
                    console.error(err)
                    reject()
                })
        })
    }

    // get price levels from sessions to save to event  
    const getPriceLevels = () => {
        let pricingLevels = sessionStorage.getItem('priceLevels')
        pricingLevels = JSON.parse(pricingLevels)
        return pricingLevels
    }

    // when leaving seat map 
    const handleLeave = () => {
        setIsReloading(true)
        // save to offer 
        handleSaveToOffer().then(() => {
            // all seats assigned to price levels 
            const allPriceLevelSeats = Object.values(getPriceLevels())?.flatMap(level => level.seats)
            setStandardAdmissionOfferHasInventory(allPriceLevelSeats?.length == totalSeats?.length)
            // if template update template 
            if (isTemplate) handleSave(true)
            loadEvent(eventId).then((res) => {
                updateEvent(res?.data)
                navigate(`/myevent/${eventId}/offers`)
                setIsReloading(false)
            })
        })
    }

    return (
        <>
            {isReloading ? (
                <PageLoadingContainer style="without-sidebar" size='sm' />
            ) : (
                <>
                    <Navigation
                        event={event}
                        isEventPublished={isEventPublished}
                        isSaved={isConfigSaved}
                        configuration={configuration}
                        templateName={template?.name}
                        unassignedSeats={Object.values(unassignedSeats)[0]}
                        activeTab={activeTab}
                        setActiveTab={setActiveTab}
                        handleShowResetConfiguration={handleShowResetConfiguration}
                        handleShowSave={handleShowSave}
                        handleLeave={handleLeave}
                    />
                    <MapConfigurations
                        isEventOnsale={isEventOnsale}
                        data={data}
                        setData={setData}
                        event={event}
                        activeTab={activeTab}
                        priceLevels={priceLevels}
                        setPriceLevels={setPriceLevels}
                        unassignedSeats={unassignedSeats}
                        setUnassignedSeats={setUnassignedSeats}
                        offers={offers}
                        setOffers={setOffers}
                        totalSeats={totalSeats}
                        totalGASeats={totalGASeats}
                        setIsReloading={setIsReloading}
                        getSeatIds={getSeatIds}
                        assignSeatsTo={assignSeatsTo}
                        removeSeatsFrom={removeSeatsFrom}
                        reloadEvent={loadEvent}
                        getDefaultObj={getDefaultObj}
                        getOpenInventory={getOpenInventory}
                        background={background}
                    />
                </>
            )}

            <ResetConfigurationModal show={showResetConfiguration} handleClose={handleCloseResetConfiguration} handleClick={handleClick} />

            <ResetMapModal show={showReset} handleReset={handleReset} handleClose={handleCloseReset} />

            <SelectMapModal show={show} choice={choice} maps={seatmaps} templates={templates} selected={selectedId} setSelected={setSelectedId} initialSelected={initialSelectedId} isTemplate={isTemplate} isSaving={isSaving} handleClose={handleClose} handleCancel={handleCancel} handleSubmit={handleSubmit} />

            <ConfirmTemplateModal show={showConfirmTemplate} step={confirmTemplateStep} name={template?.name} isSaving={isSaving} handleClose={handleCloseConfirmTemplate} handleSubmit={handleSubmitConfirmTemplate} />

            <SaveModal show={showSave} step={saveStep} setStep={setSaveStep} name={templateName} setName={setTemplateName} isSaving={isSaving} handleClose={handleCloseSave} handleCancel={handleCancelSave} handleSubmit={handleSubmitSave} />

            <ConfirmSaveTemplateModal show={showConfirmSave} name={templateName} handleClose={handleCloseConfirmSave} />
        </>
    )
}