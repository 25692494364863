import React, { useState, useEffect, useContext } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import isEqual from "lodash/isEqual";
import moment from "moment"

import LoadingContext from "../../context/Loading/Loading";
import AuthService from "../../utilities/services/auth.service";
import UserContext from "../../context/User/User";
import EventDetailsContext from "../../context/EventDetails/EventDetails";

import {
    getEvent,
    getEventDiscount,
    removeTicketGroup,
} from "../../utilities/api";
import { checkPermission, getTimezoneDate } from "../../utilities/helpers";

import Stack from "react-bootstrap/Stack";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";

import { EmptyContainer } from "../EmptyContainer";
import { CreateOfferWrapper } from "./CreateOfferWrapper";
import { Offers } from "./Offers";
import { NoPermissionsContainer } from "../NoPermissionsContainer";
import { PageLoadingContainer } from "../PageLoadingContainer";
import { Discounts } from "./Discounts";
import { CreateOfferModal } from "./CreateOfferModal";

export default function OffersWrapper({ eventId, id }) {
    const navigate = useNavigate();
    const location = useLocation();

    const { isLoading, showLoading, hideLoading } = useContext(LoadingContext);

    const { orgPermissions } = useContext(UserContext);

    const {
        updateCanPublish,
        standardAdmissionOfferHasInventory,
        updateIsEventOnsale, isEventPublished, isEventOnsale,
    } = useContext(EventDetailsContext);

    const { getPermissions } = AuthService;

    const [hasPermission, setHasPermission] = useState(true);

    const [event, setEvent] = useState();

    const [openInventory, setOpenInventory] = useState();

    // offer type 
    const [offerType, setOfferType] = useState()

    // all offers
    const [offers, setOffers] = useState();

    // all discounts
    const [discounts, setDiscounts] = useState(null);

    // current offer
    const [offer, setOffer] = useState();

    // flag to check whether changes to offer has been made or new offer has been created to check whether to fetch data again
    // to tell if back button was clicked
    const [isChangesMade, setIsChangesMade] = useState(false);

    const [isRemoving, setIsRemoving] = useState(false);

    // create offer modal 
    const [show, setShow] = useState(false)

    const offerStatuses = {
        1: {
            type: 'onsale',
            label: 'On Sale'
        },
        2: {
            type: 'scheduled',
            label: 'Scheduled'
        },
        3: {
            type: 'ended',
            label: 'Ended'
        },
        4: {
            type: 'soldout',
            label: 'Sold Out'
        }
    }

    useEffect(() => {
        if (orgPermissions?.length > 0)
            setHasPermission(checkPermission(orgPermissions, getPermissions(), 3));
    }, [orgPermissions]);

    // only runs on initial load
    useEffect(() => {
        getData();
    }, []);

    // on location change
    useEffect(() => {
        // viewing all offers
        if (
            !location.pathname.includes("edit") &&
            !location.pathname.includes("create")
        ) {
            setOffer();
            setOfferType()
            if (isChangesMade) {
                getData();
                setIsChangesMade(false);
            }
        }
    }, [location, isChangesMade]);

    // get offer on refresh if editing
    useEffect(() => {
        if (location.pathname.includes("edit") && !offer) {
            setOffer(offers?.find((offer) => offer.id === Number(id)));
        }
    }, [location, offers]);

    const getData = () => {
        showLoading();
        getEvent(eventId)
            .then((res) => {
                setEvent(res.data);
                setOffers(res.data?.offers);
                getEventOfferDiscounts();
                if (res?.data?.offers?.length > 0)
                    setOpenInventory(Object.values(res?.data?.inventory?.offers)[0]);
                // set flags in context
                updateCanPublish();
                updateIsEventOnsale(res?.data);
            })
            .catch((err) => {
                console.error(err);
                hideLoading();
            });
    };

    const getEventOfferDiscounts = async () => {
        let res = await getEventDiscount(eventId);
        console.log("getEventDiscounts res: ", res.data);
        setDiscounts(res.data.discounts);
        hideLoading()
    };

    // create offer modal 
    const handleClose = () => setShow(false)

    // handle different views
    const handleClick = (_, id, offer) => {
        if (!id) {
            navigate("create");
            setOffer();
        } else {
            setOffer(offer);
            navigate(`edit?id=${id}`);
        }
    };

    const handleCreate = (choice) => {
        setOfferType(choice)
        handleClose()
        handleClick()
    }

    // get offer status
    const getOfferStatus = (period) => {
        console.log('period', period);
        if (period) {
            const { starts, ends } = period;
            console.log(starts, ends, isEventPublished);
            let key;

            // ended
            if (ends && getTimezoneDate(ends, event?.timezone).isBefore(getTimezoneDate(moment(), event?.timezone))) {
                key = 3
            }
            // scheduled if event is not published 
            if (!isEventPublished) {
                key = 2

                // event is published
            } else {
                console.log(isEventOnsale);
                // TODO: hook up cancelled status - scheduled or onsale but offer dates have not passed ?

                // sold out 
                if (offer?.soldOut) {
                    key = 4
                }

                // onsale
                else if (starts && getTimezoneDate(starts, event?.timezone).isSameOrBefore(getTimezoneDate(moment(), event?.timezone))) {
                    key = 1
                }

                // scheduled 
                else {
                    key = 2
                }
            }
            console.log(key);
            // get status based on key 
            return offerStatuses[key]
        }
    }

    // get availability periods of offer
    // all offers will have the availability periods of Standard Admission offer by default 
    const getAvailabilityPeriods = (currentOffer) => {
        let availabilityPeriods = [];

        // get default general onsale periods
        if (offers) availabilityPeriods = offers[0]?.availability

        // existing offer 
        // change the default periods to have the same start and end dates as periods in Standard Admission offer 
        if (currentOffer) {
            const currentOfferPeriods = currentOffer?.availability;
            const defaultAvailabilityPeriods = currentOfferPeriods?.filter(period => period?.name?.includes('General On-sale'))
            const customAvailabilityPeriods = currentOfferPeriods?.filter(period => !period?.name?.includes('General On-sale'))
            const updatedDefaultAvailabilityPeriods = defaultAvailabilityPeriods?.map((period, idx) => {
                return {
                    ...period,
                    starts: availabilityPeriods[idx]?.starts,
                    ends: availabilityPeriods[idx]?.ends
                }
            })
            availabilityPeriods = [...updatedDefaultAvailabilityPeriods, ...(customAvailabilityPeriods?.length > 0 ? customAvailabilityPeriods : [])]
        }

        return availabilityPeriods;
    }

    // update offer price depending on pricing option
    const getDiscountedPrice = (price, pricingOpt, discount) => {
        console.log(discount, price);
        switch (pricingOpt) {
            // take away amount from price
            case "decrease_by_price":
                return price - parseFloat(discount);

            // get percentage of price and take it away from price
            case "decrease_by_percent":
                return Math.abs((parseFloat(discount) / 100) * price - price);

            default:
                break;
        }
    };

    // get offer price - compare price level to standard Admission level in case standard Admission levels have changed
    // offers and standard admission basePrice is accessed by price when first created and basePrice after editing
    const getOfferPrice = (
        standardAdmissionOfferLevel,
        offerLevels,
        idx,
        pricingOption,
        discount
    ) => {
        console.log(standardAdmissionOfferLevel, offerLevels[idx]);
        // get current offer price if same basePrice as standard admission price or is a custom offer price
        if (
            isEqual(
                offerLevels[idx]?.basePrice || offerLevels[idx]?.price,
                standardAdmissionOfferLevel?.basePrice ||
                standardAdmissionOfferLevel?.price
            ) ||
            pricingOption === "custom_price"
        ) {
            return (
                offerLevels[idx]?.offerPrice?.toString() ||
                offerLevels[idx]?.price?.toString()
            );
        } else {
            // if different (means its a new price level), get standard admission basePrice with discount, if any
            const defaultPrice =
                standardAdmissionOfferLevel?.basePrice?.toString() ||
                standardAdmissionOfferLevel?.price?.toString();

            if (discount) {
                return getDiscountedPrice(defaultPrice, pricingOption, discount);
            } else {
                return defaultPrice;
            }
        }
    };

    const removeOffers = (id) => {
        return new Promise((resolve, reject) => {
            setIsRemoving(true);
            removeTicketGroup(id)
                .then((res) => {
                    setIsRemoving(false);
                    resolve();
                })
                .catch((err) => {
                    setIsRemoving(false);
                    console.error(err);
                    reject();
                });
        });
    };

    return (
        <>
            {isLoading ? (
                <PageLoadingContainer />
            ) : (
                <div className='position-relative'>
                    <section
                        className={`max-width-wrapper event-form ${!hasPermission ? "overlay" : ""
                            }`}
                    >
                        {!location.pathname.includes("/create") &&
                            !location.pathname.includes("/edit") ? (
                            <section>
                                <header className='section-header-sm section-heading--flex section-heading section-heading--secondary'>
                                    <h1>Offers</h1>
                                    {offers?.length > 0 && (
                                        <Button
                                            size='lg'
                                            disabled={
                                                event?.status === "complete" ||
                                                !standardAdmissionOfferHasInventory
                                            }
                                            onClick={() => setShow(true)}
                                            className='ms-auto'
                                        >
                                            Create offer
                                        </Button>
                                    )}
                                </header>
                                {offers?.length > 0 ? (
                                    <Stack gap={4}>
                                        <Offers
                                            timezone={event?.timezone}
                                            offers={offers}
                                            eventInventory={event?.inventory}
                                            openInventory={openInventory}
                                            handleClick={handleClick}
                                            getOfferPrice={getOfferPrice}
                                            getOfferStatus={getOfferStatus}
                                        />
                                        <Discounts discounts={discounts} offers={offers} eventTimezone={event?.timezone} getAvailabilityPeriods={getAvailabilityPeriods} />
                                    </Stack>
                                ) : (
                                    <Card body>
                                        <EmptyContainer style="center lg">
                                            <p>You must finish scaling your tickes in order to create an offer.</p>
                                            <Button
                                                onClick={() => navigate('../seatmap')}
                                            >
                                                Go to Seat Map
                                            </Button>
                                        </EmptyContainer>
                                    </Card>
                                )}
                            </section>
                        ) : (
                            <CreateOfferWrapper
                                eventId={eventId}
                                event={event}
                                id={id}
                                isDiscountedOffer={offerType === 'discounted'}
                                offers={offers}
                                currentOffer={offer}
                                eventInventory={event?.inventory}
                                openInventory={openInventory}
                                standardAdmissionOfferHasInventory={
                                    standardAdmissionOfferHasInventory
                                }
                                setIsChangesMade={setIsChangesMade}
                                getDiscountedPrice={getDiscountedPrice}
                                getAvailabilityPeriods={getAvailabilityPeriods}
                                getOfferPrice={getOfferPrice}
                                getOfferStatus={getOfferStatus}
                                removeOffers={removeOffers}
                                isRemoving={isRemoving}
                            />
                        )}
                    </section>

                    {!hasPermission && <NoPermissionsContainer />}
                </div>
            )}

            <CreateOfferModal show={show} offerType={offerType} handleClose={handleClose} handleCreate={handleCreate} />
        </>
    );
}
